import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography, Box } from '@mui/material/';
import { getIn } from 'formik';

interface values {
    value: any,
    label: string,
    useComponent?: boolean,
    component?: JSX.Element,
    specificAction?: any,
}
interface RadioButtonProps {
    disabled?: boolean,
    formik: any,
    values: values[],
    radioGroupName: string,
    formLabel?: string,
    customAction?: any,
    labelVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2',
    individualRowStyle?: any,
    rowsToApplyIndividualStylesFor?: any
}

function RadioButton({ disabled, formik, values, formLabel, customAction, radioGroupName, labelVariant, individualRowStyle, rowsToApplyIndividualStylesFor}: RadioButtonProps) {
    return (
        <FormControl disabled={disabled}>
            <FormLabel>{formLabel}</FormLabel>
            <RadioGroup
                name={radioGroupName}
                value={formik.values[radioGroupName]}
                onChange={formik.handleChange}>
                {values.map(({ value, label, useComponent, component, specificAction }, i) =>
                    <Box key={i} sx={(i === rowsToApplyIndividualStylesFor) ? individualRowStyle : {}}>
                        <FormControlLabel
                            key={i}
                            value={value}
                            control={<Radio checked={value === getIn(formik.values, radioGroupName)} onClick={(e) => { if (customAction) { customAction(e) }; if (specificAction) { specificAction(e) } }} />}
                            label={<Typography variant={labelVariant ? labelVariant : 'body1'}>{label}</Typography>} />
                        {useComponent && getIn(formik.values, radioGroupName) === value &&
                            <> {component} </>
                        }
                    </Box>
                )}
            </RadioGroup>
        </FormControl>
    );
}

export { RadioButton }

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPromotionsTable = /* GraphQL */ `mutation CreatePromotionsTable($input: CreatePromotionsTableInput!) {
  createPromotionsTable(input: $input) {
    archived
    configurations
    creation_time
    digital_experience
    entry_date
    last_modified
    promo_type
    promotion_author
    promotion_brand
    promotion_bu
    promotion_campaign
    promotion_end_utc
    promotion_entity
    promotion_function
    promotion_id
    promotion_market
    promotion_name
    promotion_owner
    promotion_start_utc
    promotion_tg
    promotion_transaction
  }
}
` as GeneratedMutation<
  APITypes.CreatePromotionsTableMutationVariables,
  APITypes.CreatePromotionsTableMutation
>;
export const createMiscellaneousValuesTable = /* GraphQL */ `mutation CreateMiscellaneousValuesTable(
  $input: CreateMiscellaneousValuesTable!
) {
  createMiscellaneousValuesTable(input: $input) {
    brands
    business_units
    campaign_types
    digital_experiences
    entities
    list_id
    markets
    prize_tags
    target_groups
  }
}
` as GeneratedMutation<
  APITypes.CreateMiscellaneousValuesTableMutationVariables,
  APITypes.CreateMiscellaneousValuesTableMutation
>;
export const updatePromotionsTable = /* GraphQL */ `mutation UpdatePromotionsTable($input: UpdatePromotionsTableInput!) {
  updatePromotionsTable(input: $input) {
    archived
    configurations
    creation_time
    digital_experience
    entry_date
    last_modified
    promo_type
    promotion_author
    promotion_brand
    promotion_bu
    promotion_campaign
    promotion_end_utc
    promotion_entity
    promotion_function
    promotion_id
    promotion_market
    promotion_name
    promotion_owner
    promotion_start_utc
    promotion_tg
    promotion_transaction
  }
}
` as GeneratedMutation<
  APITypes.UpdatePromotionsTableMutationVariables,
  APITypes.UpdatePromotionsTableMutation
>;
export const updateMiscellaneousValuesTable = /* GraphQL */ `mutation UpdateMiscellaneousValuesTable(
  $input: UpdateMiscellaneousValuesTable!
) {
  updateMiscellaneousValuesTable(input: $input) {
    brands
    business_units
    campaign_types
    digital_experiences
    entities
    list_id
    markets
    prize_tags
    target_groups
  }
}
` as GeneratedMutation<
  APITypes.UpdateMiscellaneousValuesTableMutationVariables,
  APITypes.UpdateMiscellaneousValuesTableMutation
>;
export const createPrizeCatalogueTable = /* GraphQL */ `mutation CreatePrizeCatalogueTable($input: CreatePrizeCatalogueTableInput!) {
  createPrizeCatalogueTable(input: $input) {
    active
    auto_redeem
    barcode_type
    configuration_id
    cost
    delivery_type
    desc
    entry_date
    img_url
    language_for_listing
    name
    priority
    prize_id
    redeem_desc
    redemption_limit
    redemption_link
    short_desc
    tier
    total_amount
    total_available
    total_claimed
    total_expired
    total_removed
    total_reserved
    total_redeemed
    tags
    final_state
    auto_upload_vouchers
    end_date
    start_date
    has_end_date
    has_expirable_moments
    pool_prize
    voucher_dist
    prize_activity_timezone
    exp_time
    winning_ratio
  }
}
` as GeneratedMutation<
  APITypes.CreatePrizeCatalogueTableMutationVariables,
  APITypes.CreatePrizeCatalogueTableMutation
>;
export const deletePrizeCatalogueTable = /* GraphQL */ `mutation DeletePrizeCatalogueTable($input: DeletePrizeCatalogueTable!) {
  deletePrizeCatalogueTable(input: $input) {
    active
    auto_redeem
    barcode_type
    configuration_id
    cost
    delivery_type
    desc
    entry_date
    img_url
    language_for_listing
    name
    priority
    prize_id
    redeem_desc
    redemption_limit
    redemption_link
    short_desc
    tier
    total_amount
    total_available
    total_claimed
    total_expired
    total_removed
    total_reserved
    total_redeemed
    tags
    final_state
    auto_upload_vouchers
    end_date
    start_date
    has_end_date
    has_expirable_moments
    pool_prize
    voucher_dist
    prize_activity_timezone
    exp_time
    winning_ratio
  }
}
` as GeneratedMutation<
  APITypes.DeletePrizeCatalogueTableMutationVariables,
  APITypes.DeletePrizeCatalogueTableMutation
>;
export const updatePrizeCatalogueTable = /* GraphQL */ `mutation UpdatePrizeCatalogueTable($input: UpdatePrizeCataloguetable!) {
  updatePrizeCatalogueTable(input: $input) {
    active
    auto_redeem
    barcode_type
    configuration_id
    cost
    delivery_type
    desc
    entry_date
    img_url
    language_for_listing
    name
    priority
    prize_id
    redeem_desc
    redemption_limit
    redemption_link
    short_desc
    tier
    total_amount
    total_available
    total_claimed
    total_expired
    total_removed
    total_reserved
    total_redeemed
    tags
    final_state
    auto_upload_vouchers
    end_date
    start_date
    has_end_date
    has_expirable_moments
    pool_prize
    voucher_dist
    prize_activity_timezone
    exp_time
    winning_ratio
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrizeCatalogueTableMutationVariables,
  APITypes.UpdatePrizeCatalogueTableMutation
>;
export const createCurrencyAllocationRulesTable = /* GraphQL */ `mutation CreateCurrencyAllocationRulesTable(
  $input: CreateCurrencyAllocationRuleInput!
) {
  createCurrencyAllocationRulesTable(input: $input) {
    amount
    configuration_id
    currency_id
    lot_id
    program_id
    rule_id
    jira_ticket_id
    user_koid
    rule_active
    deletion_timestamp
    validity
  }
}
` as GeneratedMutation<
  APITypes.CreateCurrencyAllocationRulesTableMutationVariables,
  APITypes.CreateCurrencyAllocationRulesTableMutation
>;
export const updateCurrencyAllocationRulesTable = /* GraphQL */ `mutation UpdateCurrencyAllocationRulesTable(
  $input: UpdateCurrencyAllocationRule!
) {
  updateCurrencyAllocationRulesTable(input: $input) {
    amount
    configuration_id
    currency_id
    lot_id
    program_id
    rule_id
    jira_ticket_id
    user_koid
    rule_active
    deletion_timestamp
    validity
  }
}
` as GeneratedMutation<
  APITypes.UpdateCurrencyAllocationRulesTableMutationVariables,
  APITypes.UpdateCurrencyAllocationRulesTableMutation
>;
export const updateCurrencyCurrencyTable = /* GraphQL */ `mutation UpdateCurrencyCurrencyTable($input: UpdateCurrency!) {
  updateCurrencyCurrencyTable(input: $input) {
    country
    currency_family
    currency_id
    entry_date
    icon_url
    name
    origin
    type
    is_active
    currency_assignment
  }
}
` as GeneratedMutation<
  APITypes.UpdateCurrencyCurrencyTableMutationVariables,
  APITypes.UpdateCurrencyCurrencyTableMutation
>;
export const createCurrencyCurrencyTable = /* GraphQL */ `mutation CreateCurrencyCurrencyTable($input: CreateCurrencyInput!) {
  createCurrencyCurrencyTable(input: $input) {
    country
    currency_family
    currency_id
    entry_date
    icon_url
    name
    origin
    type
    is_active
    currency_assignment
  }
}
` as GeneratedMutation<
  APITypes.CreateCurrencyCurrencyTableMutationVariables,
  APITypes.CreateCurrencyCurrencyTableMutation
>;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const generateWinningMoments = /* GraphQL */ `query GenerateWinningMoments(
  $winningMomentsGeneratorLambdaInput: winningMomentsGeneratorLambdaInput
) {
  generateWinningMoments(
    winningMomentsGeneratorLambdaInput: $winningMomentsGeneratorLambdaInput
  ) {
    body
  }
}
` as GeneratedQuery<
  APITypes.GenerateWinningMomentsQueryVariables,
  APITypes.GenerateWinningMomentsQuery
>;
export const getPrizeDrawParticipations = /* GraphQL */ `query GetPrizeDrawParticipations($prizeDrawLambdaInput: prizeDrawLambdaInput) {
  getPrizeDrawParticipations(prizeDrawLambdaInput: $prizeDrawLambdaInput) {
    body
  }
}
` as GeneratedQuery<
  APITypes.GetPrizeDrawParticipationsQueryVariables,
  APITypes.GetPrizeDrawParticipationsQuery
>;
export const getLotteryParticipations = /* GraphQL */ `query GetLotteryParticipations($lotteryLambdaInput: lotteryLambdaInput) {
  getLotteryParticipations(lotteryLambdaInput: $lotteryLambdaInput) {
    body
  }
}
` as GeneratedQuery<
  APITypes.GetLotteryParticipationsQueryVariables,
  APITypes.GetLotteryParticipationsQuery
>;
export const getWinningMoments = /* GraphQL */ `query GetWinningMoments($configurationId: String, $prizeId: String) {
  getWinningMoments(configurationId: $configurationId, prizeId: $prizeId) {
    items {
      configuration_id
      gmt_start
      prize_id
      status
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetWinningMomentsQueryVariables,
  APITypes.GetWinningMomentsQuery
>;
export const getActiveCampaigns = /* GraphQL */ `query GetActiveCampaigns(
  $campaignId: String
  $campaignName: String
  $configArrSize: Int
) {
  getActiveCampaigns(
    campaignId: $campaignId
    campaignName: $campaignName
    configArrSize: $configArrSize
  ) {
    items {
      archived
      configurations
      creation_time
      digital_experience
      entry_date
      last_modified
      promo_type
      promotion_author
      promotion_brand
      promotion_bu
      promotion_campaign
      promotion_end_utc
      promotion_entity
      promotion_function
      promotion_id
      promotion_market
      promotion_name
      promotion_owner
      promotion_start_utc
      promotion_tg
      promotion_transaction
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetActiveCampaignsQueryVariables,
  APITypes.GetActiveCampaignsQuery
>;
export const getAllCampaigns = /* GraphQL */ `query GetAllCampaigns($campaignId: String, $campaignName: String) {
  getAllCampaigns(campaignId: $campaignId, campaignName: $campaignName) {
    items {
      archived
      configurations
      creation_time
      digital_experience
      entry_date
      last_modified
      promo_type
      promotion_author
      promotion_brand
      promotion_bu
      promotion_campaign
      promotion_end_utc
      promotion_entity
      promotion_function
      promotion_id
      promotion_market
      promotion_name
      promotion_owner
      promotion_start_utc
      promotion_tg
      promotion_transaction
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetAllCampaignsQueryVariables,
  APITypes.GetAllCampaignsQuery
>;
export const getDraftCampaigns = /* GraphQL */ `query GetDraftCampaigns(
  $campaignId: String
  $campaignName: String
  $configArrSize: Int
) {
  getDraftCampaigns(
    campaignId: $campaignId
    campaignName: $campaignName
    configArrSize: $configArrSize
  ) {
    items {
      archived
      configurations
      creation_time
      digital_experience
      entry_date
      last_modified
      promo_type
      promotion_author
      promotion_brand
      promotion_bu
      promotion_campaign
      promotion_end_utc
      promotion_entity
      promotion_function
      promotion_id
      promotion_market
      promotion_name
      promotion_owner
      promotion_start_utc
      promotion_tg
      promotion_transaction
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetDraftCampaignsQueryVariables,
  APITypes.GetDraftCampaignsQuery
>;
export const getArchivedCampaigns = /* GraphQL */ `query GetArchivedCampaigns($campaignId: String, $campaignName: String) {
  getArchivedCampaigns(campaignId: $campaignId, campaignName: $campaignName) {
    items {
      archived
      configurations
      creation_time
      digital_experience
      entry_date
      last_modified
      promo_type
      promotion_author
      promotion_brand
      promotion_bu
      promotion_campaign
      promotion_end_utc
      promotion_entity
      promotion_function
      promotion_id
      promotion_market
      promotion_name
      promotion_owner
      promotion_start_utc
      promotion_tg
      promotion_transaction
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetArchivedCampaignsQueryVariables,
  APITypes.GetArchivedCampaignsQuery
>;
export const getCurrencyTable = /* GraphQL */ `query GetCurrencyTable {
  getCurrencyTable {
    items {
      country
      currency_family
      currency_id
      entry_date
      icon_url
      name
      origin
      type
      is_active
      currency_assignment
    }
  }
}
` as GeneratedQuery<
  APITypes.GetCurrencyTableQueryVariables,
  APITypes.GetCurrencyTableQuery
>;
export const getPromotionsTable = /* GraphQL */ `query GetPromotionsTable($promotion_id: String!) {
  getPromotionsTable(promotion_id: $promotion_id) {
    archived
    configurations
    creation_time
    digital_experience
    entry_date
    last_modified
    promo_type
    promotion_author
    promotion_brand
    promotion_bu
    promotion_campaign
    promotion_end_utc
    promotion_entity
    promotion_function
    promotion_id
    promotion_market
    promotion_name
    promotion_owner
    promotion_start_utc
    promotion_tg
    promotion_transaction
  }
}
` as GeneratedQuery<
  APITypes.GetPromotionsTableQueryVariables,
  APITypes.GetPromotionsTableQuery
>;
export const getMiscellaneousValuesTable = /* GraphQL */ `query GetMiscellaneousValuesTable($list_id: String!) {
  getMiscellaneousValuesTable(list_id: $list_id) {
    items {
      brands
      business_units
      campaign_types
      digital_experiences
      entities
      list_id
      markets
      prize_tags
      target_groups
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetMiscellaneousValuesTableQueryVariables,
  APITypes.GetMiscellaneousValuesTableQuery
>;
export const getEmailTemplates = /* GraphQL */ `query GetEmailTemplates($promotionCountry: String) {
  getEmailTemplates(promotionCountry: $promotionCountry) {
    items {
      template_id
      template_name
    }
  }
}
` as GeneratedQuery<
  APITypes.GetEmailTemplatesQueryVariables,
  APITypes.GetEmailTemplatesQuery
>;
export const getPrizesForConfig = /* GraphQL */ `query GetPrizesForConfig($configuration_id: String) {
  getPrizesForConfig(configuration_id: $configuration_id) {
    items {
      active
      auto_redeem
      barcode_type
      configuration_id
      cost
      delivery_type
      desc
      entry_date
      img_url
      language_for_listing
      name
      priority
      prize_id
      redeem_desc
      redemption_limit
      redemption_link
      short_desc
      tier
      total_amount
      total_available
      total_claimed
      total_expired
      total_removed
      total_reserved
      total_redeemed
      tags
      final_state
      auto_upload_vouchers
      end_date
      start_date
      has_end_date
      has_expirable_moments
      pool_prize
      voucher_dist
      prize_activity_timezone
      exp_time
      winning_ratio
    }
  }
}
` as GeneratedQuery<
  APITypes.GetPrizesForConfigQueryVariables,
  APITypes.GetPrizesForConfigQuery
>;
export const getAllocationRulesForConfig = /* GraphQL */ `query GetAllocationRulesForConfig($configuration_id: String) {
  getAllocationRulesForConfig(configuration_id: $configuration_id) {
    items {
      amount
      configuration_id
      currency_id
      lot_id
      program_id
      rule_id
      jira_ticket_id
      user_koid
      rule_active
      deletion_timestamp
      validity
    }
  }
}
` as GeneratedQuery<
  APITypes.GetAllocationRulesForConfigQueryVariables,
  APITypes.GetAllocationRulesForConfigQuery
>;
export const getCurrencyIdTransactionTable = /* GraphQL */ `query GetCurrencyIdTransactionTable($currency_id: String!) {
  getCurrencyIdTransactionTable(currency_id: $currency_id) {
    items {
      currency_id
      entry_date
      gpp_user_id
      transaction_timestamp
      valid_thru
    }
  }
}
` as GeneratedQuery<
  APITypes.GetCurrencyIdTransactionTableQueryVariables,
  APITypes.GetCurrencyIdTransactionTableQuery
>;
export const getPrizeForConfig = /* GraphQL */ `query GetPrizeForConfig($configuration_id: String!, $prize_id: String!) {
  getPrizeForConfig(configuration_id: $configuration_id, prize_id: $prize_id) {
    active
    auto_redeem
    barcode_type
    configuration_id
    cost
    delivery_type
    desc
    entry_date
    img_url
    language_for_listing
    name
    priority
    prize_id
    redeem_desc
    redemption_limit
    redemption_link
    short_desc
    tier
    total_amount
    total_available
    total_claimed
    total_expired
    total_removed
    total_reserved
    total_redeemed
    tags
    final_state
    auto_upload_vouchers
    end_date
    start_date
    has_end_date
    has_expirable_moments
    pool_prize
    voucher_dist
    prize_activity_timezone
    exp_time
    winning_ratio
  }
}
` as GeneratedQuery<
  APITypes.GetPrizeForConfigQueryVariables,
  APITypes.GetPrizeForConfigQuery
>;

import { useMemo, useState } from 'react';
import { flowState } from '../../pages/AddPrize/AddPrizePage';
import { Autocomplete, Box, Grid, Typography } from '@mui/material';
import { TooltipText } from '../../components/inputComponents/TooltipText';
import { RadioButton } from '../../components/inputComponents/RadioButton';
import { InputNumberSpinner } from '../../components/inputComponents/InputNumberSpinner';
import { barcodeType, deliveryType } from '../../constants/lists';
import { InputComponent } from '../inputComponents/InputComponent';
import { MultipleSelectOptions } from '../selectComponents/MultipleSelectOptions';
import { AddToListModal } from '../modals/AddToListModal';
import * as yup from 'yup';
import { getIn } from 'formik';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

const tags = [1];

interface props {
    isEdit?: boolean;
}

type barcodeItemType = {
    value: string;
    label: string;
};

const tagsValidationSchema = yup.object({
    tag: yup
        .string()
        .matches(/.*/)
        .test('Max length', 'Max length is 250 characters', (val: any) => val && val.toString().length <= 250)
        .required('Field is required'),
});

function AdditionalDetails({ isEdit, formik }: flowState & props) {
    const [open, setOpen] = useState(false);

    const barcodeTypeMap = useMemo(
        () =>
            barcodeType.reduce((acc: { [key: string]: barcodeItemType }, item: barcodeItemType) => {
                acc[item.value] = item;
                return acc;
            }, {}),
        []
    );

    const handleOpen = () => setOpen(true);

    return (
        <Box className='prize-box-main'>
            <Typography variant='h4' gutterBottom>
                Additional Details
            </Typography>
            <Typography variant='body1' gutterBottom>
                Specify additional details for this prize
            </Typography>
            <Box className='additional-details-content'>
                <TooltipText
                    styleName='prize-tooltip-text'
                    text='Voucher Distribution'
                    textVariant='body1'
                    tooltip="No can be selected for digital prizes that don't require vouchers as the proof of winning in the promo, e.g. uploaded assets or hidden URLs"
                />
                <RadioButton
                    disabled={isEdit}
                    labelVariant='body2'
                    formik={formik}
                    values={[
                        { value: 'true', label: 'Yes' },
                        { value: 'false', label: 'No' },
                    ]}
                    radioGroupName={'voucher_dist'}
                />
                {formik.values.voucher_dist === 'true' && (
                    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
                        <Grid item xs={10}>
                            <TooltipText
                                styleName='prize-tooltip-text'
                                text='Barcode Type'
                                textVariant='body1'
                                tooltip='Select the barcode type that will be used for the prize voucher distribution.'
                            />
                            <Autocomplete
                                disableClearable={true}
                                sx={{ background: 'white' }}
                                fullWidth
                                data-testid='barcode-type-dropdown'
                                options={barcodeType}
                                value={barcodeTypeMap[formik.values.barcode_type]}
                                onChange={(_event, value: barcodeItemType) =>
                                    formik.handleChange({
                                        target: { name: 'barcode_type', value: parseInt(value.value) },
                                    })
                                }
                                renderInput={(params) => (
                                    <TextFieldWrapper {...params} placeholder='Select Barcode Type' />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>Delivery Type</Typography>
                            <RadioButton
                                labelVariant='body2'
                                formik={formik}
                                values={deliveryType}
                                radioGroupName={'delivery_type'}
                            />
                        </Grid>
                    </Grid>
                )}
                {formik.values.voucher_dist === 'false' && (
                    <>
                        <InputComponent
                            inputClassName='input-space'
                            formik={formik}
                            inputValue={getIn(formik.values, 'total_amount')}
                            formikLabel={'total_amount'}
                            title='Total Prize Quantity'
                            titleVariant='body1'
                        />
                        <InputComponent
                            inputClassName='input-space'
                            formik={formik}
                            inputValue={getIn(formik.values, 'total_available')}
                            formikLabel={'total_available'}
                            title='Available Prize Quantity'
                            titleVariant='body1'
                        />
                    </>
                )}
                <TooltipText
                    styleName='prize-tooltip-text'
                    text='Final State'
                    textVariant='body1'
                    tooltip="Indicate the final state of this prize.
                        If winners still have to redeem the prize on partner's website or physical location which are integrated with NGPS backend to change prize status, select 'Claimed' option. This informs NGPS about the actual redemption event and can be useful for tracking and analytics.
                        If you don't have a programmatic way to update the redemption status, or the prize can be treated as redeemed straight after it was distributed, select 'Redeemed' option."
                />
                <RadioButton
                    labelVariant='body2'
                    formik={formik}
                    values={[
                        { value: 'redeemed', label: 'Redeemed' },
                        { value: 'claimed', label: 'Claimed' },
                    ]}
                    radioGroupName={'final_state'}
                />
                <TooltipText
                    styleName='prize-tooltip-text'
                    text='Order in Prize Catalog'
                    textVariant='body1'
                    tooltip='If you want to order prizes in the catalog by their priority, specify the number here, where 1 has the highest priority.'
                />
                <InputNumberSpinner fieldName={'priority'} incrementValue={1} />
                <MultipleSelectOptions
                    formik={formik}
                    listOfOptions={[...formik.values.tags, ...tags]}
                    formikFieldName='tags'
                    subTitle='Tags'
                    inputLabel='Select Tag(s)'
                    enableAdditional={true}
                    additionalLabel='+ Add New Tag'
                    handleOpen={handleOpen}
                    modalComponent={
                        <AddToListModal
                            formikState={formik}
                            formikFieldName='tags'
                            value='tag'
                            schema={tagsValidationSchema}
                            header='+ Add New Tag'
                            modalOpenState={open}
                            listToInsertTo={tags}
                            handleClose={setOpen}
                        />
                    }
                />
            </Box>
        </Box>
    );
}
export { AdditionalDetails };


import { Formik } from 'formik';
import { useState } from 'react';
import moment from 'moment-timezone';
import { Box, Typography, Stepper, Step, StepLabel, CircularProgress, Backdrop } from '@mui/material';
import { useParams } from "react-router";
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { createPrizeCatalogueTable, updatePrizeCatalogueTable } from '../../graphql/mutations';
import { AddPrizeForm } from './AddPrizeForm';
import { SubNav } from '../../common/SubNav/SubNav';
import { Notification } from '../../common/Notification/Notification';
import { NotFoundPage } from '../NotFound/NotFoundPage';
import { addPrizeValidationSchema } from './AddPrizeValidationSchema';
import { useLocation } from 'react-router-dom';
import { AddPrizeState } from "../../types/componentTypes/prizeComponentTypes";
import useGetPrizeStateDetails from './hooks/useGetPrizeStateDetails';

import './AddPrize.css'

interface flowState {
    formik?: any,
}

const steps = [
    'Prize Localization',
    'Prize Type',
    'Prize State & Timeline',
    'Winning Limit',
    'Additional Details',
];

Storage.configure({
    customPrefix: {
        public: '',
        protected: '',
        private: ''
    }
});

const initialValues: AddPrizeState = {
    tempParams: {
        start_date: '',
        end_date: '',
        prizeStatus: 'active',
        winningLimit: false,
        country: '',
        img_url: [],
        mechanic: 'iw'
    },
    configuration_id: '',
    prize_id: '',
    name: [],
    desc: [],
    short_desc: [],
    redeem_desc: [],
    redemption_link: '',
    img_url: [],
    redemption_limit: null,
    barcode_type: '0',
    delivery_type: '1',
    final_state: 'redeemed',
    pool_prize: 'false',
    auto_redeem: 'false',
    priority: null,
    start_date: '',
    end_date: '',
    tier: 0,
    voucher_dist: 'true',
    total_amount: 0,
    total_available: 0,
    tags: [],
    prize_activity_timezone: '',
    active: true,
    entry_date: moment().format('YYYY-MM-DD'),
    cost: [{
        amount: null,
        currency_id: '',
        name: ''
    }]
}

const validationSchema = addPrizeValidationSchema();

function AddPrizePage() {
    const [initialState, setInitialState] = useState<AddPrizeState>(initialValues);
    const [reset, setReset] = useState(false);
    const {configurationId, campaignId} = useParams();
    const { state } = useLocation();

    const {
        notificationState,
        disabled,
        loading,
        editState,
        configData,
        tabLabels,
        setNotificationState,
        setEditState,
    } = useGetPrizeStateDetails({initialValues, state, configurationId, reset, setReset, setInitialState});
    
    const [step, setStep] = useState(0);
    const updateStep = (num: number) => {
        const newStep = step + num
        setStep(newStep);
    };
    
    const linksMapper = [{ name: "Campaigns", href: "/listCampaigns" }, { name: `${campaignId}`, href: `/editCampaign/${campaignId}` }, { name: `${configData?.configurationParameters?.additionalInformation?.name || ''} [ID: ${configurationId}]` }]

    const transfromToMap = (values : {[key:string]: any}) => {
        const stateValues = ['name', 'desc', 'short_desc', 'redeem_desc'];
        stateValues.forEach((value:string ) => {
            const arrayValue = values[value];
            const mapValue = Object.assign({}, ...arrayValue);
            values[value] = JSON.stringify(mapValue)
           })
        return values;
    }

    const checkAndAddWinningLimit = async (values : {[key:string]: any}) => {
        delete values.auto_redeem;
        delete values.cost;
        try {
            if ((values.redemption_limit !== null && values.redemption_limit > 0) && configData.flow.instantWin) {
                configData.flow.instantWin = {
                    ...configData.flow.instantWin,
                    params: {
                        ...configData.flow.instantWin?.params,
                        winningLimitsPerPrize: {
                            ...configData?.flow?.instantWin?.params?.winningLimitsPerPrize,
                            [values.prize_id]: parseInt(values.redemption_limit)
                        }
                    }
                }
                delete values.redemption_limit;
                const fileName = `${values.configuration_id}/conf.txt`;
                await Storage.put(fileName, JSON.stringify(configData), { contentType: 'text/plain'});
                } else if ((values.redemption_limit === null || values.redemption_limit <= 0) && configData.flow.instantWin?.params?.winningLimitsPerPrize){
                    const {[values.prize_id]:_, ...rest} = configData.flow.instantWin.params.winningLimitsPerPrize;
                    if (Object.keys(rest).length > 0 ) {
                        configData.flow.instantWin.params.winningLimitsPerPrize = {...rest}
                    } else {
                        delete configData.flow.instantWin.params.winningLimitsPerPrize;
                    }
                    delete values.redemption_limit;

                    const fileName = `${values.configuration_id}/conf.txt`;
                    await Storage.put(fileName, JSON.stringify(configData), { contentType: 'text/plain'})
                }
                return values
        } catch (err) {
            console.error("Error:", err)
        }
    }

    return (
        <>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
            open={loading}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState}/>
            {disabled && <NotFoundPage/>}
            {!disabled && <>
            <Box sx={{ marginBottom: 2 }}>
                <SubNav linksMapper={linksMapper} breadcrumbsStyle='subnav' textVariant='overline' linkClass='subnav-link' />
            </Box>
            <Box>
                <Typography variant='h4' gutterBottom>Configure Prize</Typography>
                <Formik
                    initialValues={initialState}
                    enableReinitialize={true}
                    validationSchema = {validationSchema}
                    onSubmit={async (values) => {
                        try{
                            const {tempParams, ...mainValues} = values;
                            const data = transfromToMap(mainValues);
                            const finalResult = (
                            configData?.flow?.instantWin
                                ? await checkAndAddWinningLimit(data)
                                :  ( delete data.pool_prize, {...data, cost: JSON.stringify(data.cost)} )
                            )

                            // TODO: This has been commented out as these values should not be used yet @13/11/2023
                            // const expirationTimestamp: number = configData?.configurationParameters?.configurationEndUtc
                            // ? Number(configData.configurationParameters.configurationEndUtc) + (1000 * 60 * 60 * 24 * 30)
                            // : new Date().setFullYear(new Date().getFullYear() + 10);
                            // finalResult.exp_time = Math.floor(expirationTimestamp / 1000);
                            editState
                            ? await API.graphql(graphqlOperation(updatePrizeCatalogueTable, { input: finalResult }))
                            : await API.graphql(graphqlOperation(createPrizeCatalogueTable, { input: finalResult }))
                            setEditState(null);
                            updateStep(1);
                        } catch (e) {
                            console.error("Save failed with: ", e);
                            setNotificationState({
                                open:true,
                                title:'Error',
                                content:`Prize creation failed! Please check console for detailed error.`,
                                level: "error"
                            })
                        }
                    }}
                >
                    {formik => (
                        <form onSubmit={formik.submitForm}>
                            <AddPrizeForm isEdit={!!editState} configurationData={configData} step={step} updateStep={updateStep} tabs={tabLabels} setReset={setReset} initialValues={initialValues} setNotificationState={setNotificationState} />
                        </form>
                    )}
                </Formik>
                <Stepper activeStep={step} alternativeLabel className='addPrize-stepper'>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            </>}
        </>
    )
}

export { AddPrizePage }
export type { flowState }

import { TextField, TextFieldProps } from '@mui/material';

interface ITextFieldWrapperProps {
    blockEnterKey?: boolean;
}

const TextFieldWrapper = ({ blockEnterKey = true, ...props }: TextFieldProps & ITextFieldWrapperProps) => {
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) =>
        blockEnterKey && event.key.toLowerCase() === 'enter' ? event.preventDefault() : null;

    return <TextField {...props} onKeyDown={onKeyDownHandler} />;
};

export default TextFieldWrapper;

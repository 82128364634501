import { Box, Typography, FormHelperText, Grid } from '@mui/material';
import { getIn } from 'formik';
import { useState, useEffect } from 'react';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';
import {
    formatDecimalPlaces,
    clampNumber,
    getFinalFormikValue,
    getFilteredValue,
} from './helpers/input-component-helpers';

interface NameProps {
    formik: any;
    formikLabel: any;
    label?: string;
    placeholder?: string;
    title?: string;
    text?: string;
    inputClassName?: string;
    inputFieldClass?: string;
    inputPropsClass?: string;
    textVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2' | 'caption';
    titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2' | 'caption';
    errorPath?: string;
    disabled?: boolean;
    multiline?: boolean;
    rows?: number;
    dataTestId?: string;
    touchedErrorPath?: string;
    minRows?: number;
    shouldFocusOnElement?: boolean;
    inputValue: string | number;
    blockEnterKey?: boolean;
    type?: 'string' | 'number';
    shouldShowError?: boolean;
    min?: number;
    max?: number;
}

function InputComponent({
    formik,
    formikLabel,
    title,
    titleVariant,
    text,
    textVariant,
    label,
    inputClassName,
    inputFieldClass,
    inputPropsClass,
    errorPath,
    disabled = false,
    multiline = false,
    rows,
    dataTestId,
    placeholder,
    touchedErrorPath,
    minRows,
    shouldFocusOnElement = false,
    inputValue,
    blockEnterKey,
    type: inputType = 'string',
    shouldShowError = true,
    min = 1,
    max = 1000,
}: NameProps): React.ReactElement {
    const errorFormikPath = errorPath ? errorPath : formikLabel;
    const [compValue, setCompValue] = useState(inputValue);

    useEffect(() => setCompValue(inputValue), [inputValue]);

    const elementError =
        getIn(formik.touched, touchedErrorPath || errorFormikPath) && getIn(formik.errors, errorFormikPath);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (inputType !== 'number') return updateStateValues(value, name);

        const { filteredValue, isValidNumber } = getFilteredValue(value);
        if (!isValidNumber) return updateStateValues(value, name);

        const adjustedValue = filteredValue.includes('.')
            ? formatDecimalPlaces({ numberStr: filteredValue, min, max })
            : clampNumber({ number: +filteredValue, max, min });

        return updateStateValues(adjustedValue.toString(), name);
    };

    function updateStateValues(value: string, name: string) {
        setCompValue(value);
        formik.setFieldValue(name, getFinalFormikValue(value));
    }

    return (
        <Box className={inputClassName}>
            {title && (
                <Typography variant={titleVariant ? titleVariant : 'h3'} gutterBottom>
                    {title}
                </Typography>
            )}
            {text && (
                <Typography variant={textVariant ? textVariant : 'body1'} sx={{ marginBottom: 2 }}>
                    {text}
                </Typography>
            )}
            <Grid container direction='column'>
                <Grid item className={inputFieldClass}>
                    <TextFieldWrapper
                        type={inputType}
                        blockEnterKey={blockEnterKey}
                        autoFocus={shouldFocusOnElement}
                        className={`input-text ${inputFieldClass}`}
                        fullWidth
                        disabled={disabled}
                        id={`outlined-basic-${formikLabel}`}
                        label={label}
                        placeholder={placeholder}
                        name={formikLabel}
                        variant='outlined'
                        multiline={multiline}
                        minRows={minRows}
                        rows={rows}
                        value={compValue}
                        onBlur={formik.handleBlur}
                        onChange={handleInputChange}
                        inputProps={{
                            className: inputPropsClass,
                            'aria-label': formikLabel,
                            'data-testid': dataTestId,
                            ...(inputType === 'number' ? { max, min, step: min || 0.01 } : {}),
                        }}
                        error={!!elementError}
                    />

                    {shouldShowError && (
                        <FormHelperText data-testid={`${dataTestId}-error`} error={true}>
                            {elementError}
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export { InputComponent };

import { Box } from "@mui/material";
import { CheckboxComponent } from "../../inputComponents/CheckboxComponent";
import { TooltipText } from "../../inputComponents/TooltipText";
import { InterruptedFlow } from "../AdditionalPromoDetails/InterruptedFlow";
import "./AdditionalPromoDetails.css";


interface AdditionalDetailsMechanicSpecificsProps {
  mechanic: string;
  customActions: any[];
  formik: any;
}

function AdditionalDetailsMechanicSpecifics({
  formik,
  mechanic,
  customActions,
}: AdditionalDetailsMechanicSpecificsProps) {
  return (
    <>
      {mechanic === "Collect & Get" && (
        <Box className="cngAddDetailsBox">
          <CheckboxComponent
            customBoxClass={"voucher-checkbox"}
            fieldName={"tempParams.redeemPrize"}
            label={
              <TooltipText
                styleName="details-checkbox-tooltip"
                text="Prize Redemption (Optional)"
                textVariant="body1"
                tooltip={
                  "Once enabled, participants can redeem prizes even if no vouchers are distributed"
                }
              />
            }
            customAction={customActions[0]}
          />
          <CheckboxComponent
            customBoxClass={"voucher-checkbox"}
            fieldName={"tempParams.addTransaction"}
            label={
              <TooltipText
                styleName="details-checkbox-tooltip"
                text="Transactions (Optional)"
                textVariant="body1"
                tooltip={
                  "Once enabled, the amount of every participant's wallet can be modified"
                }
              />
            }
            customAction={customActions[0]}
          />
          <CheckboxComponent
            customBoxClass={"voucher-checkbox"}
            fieldName={"tempParams.redeemVoucher"}
            label={
              <TooltipText
                styleName="details-checkbox-tooltip"
                text="Vouchers Redemption (Optional)"
                textVariant="body1"
                tooltip={
                  "This functionality gives participants the ability to redeem vouchers for prizes"
                }
              />
            }
            customAction={customActions[0]}
          />
          <CheckboxComponent
            customBoxClass={"voucher-checkbox"}
            fieldName={"tempParams.queryWallet"}
            label={
              <TooltipText
                styleName="details-checkbox-tooltip"
                text="Wallet (Optional)"
                textVariant="body1"
                tooltip={
                  "Once enabled, participants will be able to see their wallets"
                }
              />
            }
            customAction={customActions[0]}
          />
        </Box>
      )}
      {mechanic === "Instant Win" && (
        <InterruptedFlow
          formik={formik}
        />
      )}
    </>
  );
}

export { AdditionalDetailsMechanicSpecifics };

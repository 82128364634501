import { Icon, Tooltip, Grid, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { TooltipProps, ToolTipPlacement } from '../../types/componentTypes/tooltip';
import './TooltipText.css'

function TooltipText({ text, textVariant, tooltip, iconSize, styleName, placement }: TooltipProps) {
    return (
        <Grid container={true} className={styleName ? styleName : 'tooltipGrid'}>
            <Typography variant={textVariant}>{text} </Typography>
            <Tooltip sx={{ marginTop: "-2px" }} classes={{ tooltip: 'tooltip_text' }} title={tooltip} placement={placement || ToolTipPlacement.Bottom}>
                <Icon className='icon-style' fontSize={iconSize ? iconSize : 'large'}>
                    <HelpOutlineIcon />
                </Icon>
            </Tooltip>
        </Grid>
    )
}

export { TooltipText }

const ALLOWED_STAGES = ['apac', 'latam'];

const makeEncodedUrl = ({
    makeDefaultUrl,
    env,
    path,
    region,
}: {
    makeDefaultUrl: boolean;
    env: string;
    path: string;
    region?: string;
}) =>
    makeDefaultUrl
        ? encodeURI(`https://public.${env}.ngps.coca-cola.com/${path}`)
        : encodeURI(`https://public.${env}.${region}.ngps.coca-cola.com/${path}`);

export const appendPublicDomainName = ({ path, bucketName }: { path: string; bucketName: string }) => {
    const strArr = bucketName.split('-');
    const env = strArr[strArr.length - 1];

    const region = strArr[1];
    const isValidRegion = ALLOWED_STAGES.includes(region);

    if (bucketName.includes('ss')) {
        console.error('Can not save file to SS buckets, saving to default location');
        return makeEncodedUrl({
            makeDefaultUrl: isValidRegion ? false : true,
            env,
            path,
            region: isValidRegion ? region : '',
        });
    }

    if (strArr.length === 5 && isValidRegion) {
        !isValidRegion
            ? console.error('region not found in allowed regions defaulting to default save location')
            : console.log('saving to regional bucket');
        return makeEncodedUrl({
            makeDefaultUrl: isValidRegion ? false : true,
            env,
            path,
            region: isValidRegion ? region : '',
        });
    }

    return makeEncodedUrl({ makeDefaultUrl: true, env, path });
};

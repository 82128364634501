import { currenciesRef } from "../../../../constants/helpful-link";

export const helpfulLinks = {
    instantWin: [
        {
            link: currenciesRef.ALLOCATION_RULES,
            text: 'Currency Rules',
        },
        {
            link: currenciesRef.USER_WALLET,
            text: 'User Wallet',
        },
        {
            link: currenciesRef.CREATE_CURRENCY,
            text: 'How to create currency (Wiki and video)',
        },
    ],
    redeemPincodeForCurrencies: [
        {
            link: currenciesRef.BURN_PINCODE,
            text: 'Burn Pin Codes For Currencies',
        },
        {
            link: currenciesRef.CURRENCY_REDUCER,
            text: 'Currency Reducer',
        },
        {
            link: currenciesRef.CURRENCY_CHECKER,
            text: 'Currency Checker',
        },
        {
            link: currenciesRef.CREATE_CURRENCY,
            text: 'How to create currency (Wiki and video)',
        },
    ],
    promoEntry: [
        {
            link: currenciesRef.ALLOCATION_RULES,
            text: 'Currency Rules',
        },
        {
            link: currenciesRef.USER_WALLET,
            text: 'User Wallet',
        },
        {
            link: currenciesRef.CREATE_CURRENCY,
            text: 'How to create currency (Wiki and video)',
        },
    ]
};
import { useState, useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {StepSlider} from '../../common/StepSlider/StepSlider';
import moment from 'moment'
import { Formik } from 'formik';
import { campaignValidationSchema } from '../CreateCampaign/campaignValidationSchema'
import { Notification } from '../../common/Notification/Notification';
import { CreateCampaignForm } from './CreateCampaignForm';
import { API, graphqlOperation } from 'aws-amplify';
import { createPromotionsTable, createMiscellaneousValuesTable, updateMiscellaneousValuesTable } from '../../graphql/mutations';
import { Box } from '@mui/material'
import { useNavigate } from "react-router-dom";
import type { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { defaultNotifState } from '../../constants/currency-constants';

import  './CreateCampaignPage.css';

const uniqid = require('uniqid');
const validationSchema = campaignValidationSchema();
interface flowState {
    formik?: any,
}

interface createCampaignState {
    digital_experience: Array<string>,
    promotion_name: string,
    promotion_market: Array<String> | string,
    promotion_owner: string,
    promotion_author: string,
    promotion_dateRange?: DateRange<moment.Moment> | undefined,
    promotion_start_utc: number,
    promotion_end_utc: number,
    promotion_id: string,
    promotion_brand?:Array<String>
}

const uniqueIdLength = 14;

const startDate = moment();
const endDate = moment().add(30, 'days');

const initialValues: createCampaignState = {
    digital_experience: [],
    promotion_name: '',
    promotion_market: [],
    promotion_owner:'',
    promotion_author:'',
    promotion_dateRange: [startDate, endDate],
    promotion_start_utc: startDate.toDate().getTime(),
    promotion_end_utc: endDate.toDate().getTime(),
    promotion_id: '',
}

function CreateCampaignPage() {
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [customListsPresent, setCustomListsPresent] = useState(false)
    const [customListValues] = useState([])
    const [userEmailForId, setUserEmailForId] = useState('')

    const steps = [
        'Campaign Name',
        'Select experience(s)',
        'Select Market(s)',
        'Allocate dates',
    ];


    const [step, setStep] = useState(0);
    const navigate = useNavigate();

    const updateStep = (num:number) => {
        const newStep = step+num;
        if(newStep < 0){
            navigate(-1);
            return;
        }
        setStep(newStep);
    }
    useEffect(() => {
        if (!initialValues.promotion_id && step === 0) {
        initialValues.promotion_id = (uniqid() + uniqid()).substring(0, uniqueIdLength)
        };
     },[step])

    return (

     <Box className="createCampaignBox">
         <Notification notificationState={notificationState} setNotificationState={setNotificationState}/>
        <h3>Create New Campaign</h3>
        {step < 4 &&
            <StepSlider imagesFolderName="createCampaignImages" currentStep={step} className='campaignStepImages'/>}
        <Box className="wrapper">
            <Formik
                initialValues= {initialValues}
                validationSchema = {validationSchema}
                onSubmit = {async (values) => {
                    try{
                        let submitValues = {...values}
                        initialValues.promotion_id = ''
                        let miscValues = {
                            list_id: userEmailForId,
                            digital_experiences: customListValues
                        }
                        delete submitValues.promotion_dateRange;
                        await API.graphql(graphqlOperation(createPromotionsTable, {input: submitValues}));

                        if (customListsPresent && customListValues) {
                            await API.graphql(graphqlOperation(updateMiscellaneousValuesTable, {input: miscValues}))
                        } else if (!customListsPresent && customListValues) {
                            await API.graphql(graphqlOperation(createMiscellaneousValuesTable, {input: miscValues}))
                        }
                        updateStep(1);
                    } catch (e) {
                        console.error("Save failed with: ", e);
                        setNotificationState({
                            open:true,
                            title:'Error',
                            content:`Campaign creation failed! Please check console for detailed error.`,
                            level: "error"
                        })
                    }
                }}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit}>
                        <CreateCampaignForm setUserEmailForId={setUserEmailForId} customListValues={customListValues} setCustomListsPresent={setCustomListsPresent} step={step} updateStep={updateStep}/>
                    </form>)
                }
            </Formik>
            {step < 4 &&
                <Stepper activeStep={step} alternativeLabel classes={{root:"stepper"}}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            }
        </Box>
    </Box>
    )
}

export { CreateCampaignPage };
export type { createCampaignState, flowState };

import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { timezones } from "../../../constants/timezones";
import { getPrizeForConfig } from '../../../graphql/queries';
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GetPrizeForConfigResult, PrizeTableItem } from "../../../types/componentTypes/PrizeTypes";
import { AddPrizeState, emptyConfigType, PrizeState, PrizeStateDetailsProps } from "../../../types/componentTypes/prizeComponentTypes";
import { languageCodes } from '../../../constants/lists';
import { defaultNotifState } from '../../../constants/currency-constants'

const uniqid = require('uniqid');


const emptyConfig = {
    configurationId: '',
    promotionId: '',
    configurationParameters: {
        configurationDatesTimezone: '',
        configurationStartUtc: '',
        configurationEndUtc: '',
        country: '',
        additionalInformation: {
            name: ''
        },
        language: ''
    },
    flow: {
        instantWin: {
            params: {
                winningLimitsPerPrize: {}
            }
        }
    }
}


const transformMapToArray = (values : {[key:string]: any}) => {
    const stateValues = ['name', 'desc', 'short_desc', 'redeem_desc'];
    stateValues.forEach((value:string ) => {
        const mapValue = JSON.parse(values[value]);
        const arrayValue = Object.keys(mapValue).map(key => ({[key]: mapValue[key]}));
        values[value] = arrayValue
    })
    return values;
}

const stringifyBooleans = (prize: PrizeTableItem) => {
    const stateValues = ['pool_prize', 'auto_redeem', 'voucher_dist'];
    stateValues.forEach((value: string) => {
        prize[value] = JSON.stringify(!!prize[value])
    })
}

const transformUTCtoTimezone = (configDate: string, timezoneString: string)  => {
    let date: string;
    if (configDate) {
        const utcDate = moment.utc(configDate).format();
        const timezone = Object.keys(timezones).find(key => timezones[key] === timezoneString) || 'Europe/London';
        date = moment.tz(utcDate, timezone).format();
    } else {
        date = moment().add(15, 'minutes').format('YYYY-MM-DD HH:mm')
    }

    return date
}

const useGetPrizeStateDetails = ({initialValues, state, configurationId, reset, setReset, setInitialState}: PrizeStateDetailsProps) => {
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editState, setEditState] = useState(state?.prize);
    const [configData, setConfigData] = useState<emptyConfigType>(emptyConfig);
    const [tabLabels, setTabLabels] = useState<Array<string>>([]);

    useEffect(() => {
        if (!configurationId) {
            setLoading(false);
            setDisabled(true);
        }
        if(state) {
            const fetchPrize = async () => {
                try {
                    const response = await API.graphql(
                        graphqlOperation(getPrizeForConfig, { configuration_id: state.configData.configurationId, prize_id: state.prize.prize_id })
                    ) as GraphQLResult<GetPrizeForConfigResult>
                    delete response.data.getPrizeForConfig.__typename
                    return response.data.getPrizeForConfig;
                } catch (e) {
                    console.error(e);
                }
            };

            const modifyPrizeValues = (prize) => {
                stringifyBooleans(prize);
                if (!prize.tags) prize.tags = [];
                prize.barcode_type = prize.barcode_type ? JSON.stringify(prize.barcode_type) : '0';
                prize.delivery_type = prize.delivery_type ? JSON.stringify(prize.delivery_type) : '1';
                if (prize.cost) { prize.cost = JSON.parse(prize.cost) }
            }

            fetchPrize().then((prize: PrizeTableItem) => {
                const imgUrl = typeof prize.img_url === 'string' ? [prize.img_url] : prize.img_url
                const transformedPrize = { ...transformMapToArray(prize), redemption_limit: state.prize.redemption_limit } as PrizeState;
                modifyPrizeValues(transformedPrize)
                const prizeStartDate = transformUTCtoTimezone(transformedPrize.start_date as string, transformedPrize.prize_activity_timezone);
                const prizeEndDate = transformUTCtoTimezone(transformedPrize.end_date as string, transformedPrize.prize_activity_timezone);
                const configStartDate = transformUTCtoTimezone(state.prize.tempParams.start_date, state.prize.tempParams.config_timezone);
                const configEndDate = transformUTCtoTimezone(state.prize.tempParams.end_date, state.prize.tempParams.config_timezone);
                const prizeState: AddPrizeState = {
                    ...transformedPrize,
                    img_url: imgUrl,
                    tempParams: {
                        ...state.prize.tempParams,
                        img_url: imgUrl,
                        prizeStatus: prize.active ? 'active' : 'inactive'
                    }
                }
                if (!state.prize.tempParams.start_date && !state.prize.tempParams.end_date) {
                    prizeState.tempParams.start_date = (prizeState.start_date && prizeState.end_date) ? prizeStartDate : configStartDate;
                    prizeState.tempParams.end_date = (prizeState.start_date && prizeState.end_date) ? prizeEndDate : configEndDate;
                } else {
                    prizeState.tempParams.start_date = prizeStartDate;
                    prizeState.tempParams.end_date = prizeEndDate;
                    prizeState.tempParams.prizeStatus = (!moment(prizeStartDate).isSame(configStartDate)) ? 'custom' : prizeState.tempParams.prizeStatus

                }

                const languages: string[] = Object.keys(Object.assign({}, ...prizeState.name));
                languages.splice(languages.indexOf(state.configData.configurationParameters.language), 1);
                const initialLang: string[] = languages.map((lang: string) => {
                    return languageCodes[lang]
                })
                const defaultLanguage: string = languageCodes[state.configData.configurationParameters.language]
                const tabs: string[] = ['+ ADD NEW LANGUAGE', defaultLanguage, ...initialLang];

                setEditState(prizeState)
                setInitialState(prizeState);
                setConfigData(state.configData)

                setTabLabels(tabs);
                setLoading(false);

            }).catch((err) => {
                setNotificationState({
                    open:true,
                    title:'Prize edit failed',
                    content:'Prize not found or in wrong format',
                    level: "error"
                })
                console.error("Getting prize failed wtih", err);
                setLoading(false);
                setDisabled(true)
            })
        } else {
            const retrieveConfigData = async () => {
                try {
                    const configResult: any = await Storage.get(`${configurationId}/conf.txt`, { download: true, cacheControl: 'no-cache' });
                    const result = await configResult.Body.text();
                    return JSON.parse(result);
                } catch (err) {
                    console.error('Error getting promotion data', err);
                }
            }
            retrieveConfigData()
                .then(data => {
                    setConfigData(data);
                    const configStartDate = transformUTCtoTimezone(data.configurationParameters.configurationStartUtc, data.configurationParameters.configurationDatesTimezone);
                    const configEndDate = transformUTCtoTimezone(data.configurationParameters.configurationEndUtc, data.configurationParameters.configurationDatesTimezone);
                    const updatedState: AddPrizeState = {
                        ...initialValues,
                        configuration_id: data.configurationId,
                        prize_id: uniqid(),
                        start_date: configStartDate,
                        end_date: configEndDate,
                        prize_activity_timezone:  data.configurationParameters?.configurationDatesTimezone || '',
                        priority: data.flow?.redeemPincodeForCurrencies ? 1 : null,
                        tempParams: {
                            ...initialValues.tempParams,
                            start_date: configStartDate,
                            end_date: configEndDate,
                            country: data.configurationParameters?.country || '',
                            mechanic: data?.flow?.redeemPincodeForCurrencies ? 'c&g' :  data?.flow?.autoRedeemCnG ? 'autoRedeemCnG' : 'iw',
                        }
                    }
                    setInitialState(updatedState)

                    const initialLang = languageCodes[data.configurationParameters?.language] || 'English';
                    const tabs = ['+ ADD NEW LANGUAGE', initialLang];
                    setTabLabels(tabs)
                    setLoading(false);
                    setReset(false);
                })
                .catch(e => {
                    setNotificationState({
                        open:true,
                        title:'Configuration not found',
                        content:'Supplied configuration id does not exist!',
                        level: "error"
                    })
                    console.error("Retrieval of promotion metadata failed with:", e);
                    setLoading(false);
                    setDisabled(true)
                })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, configurationId, reset])

    return {
        notificationState,
        disabled,
        loading,
        editState,
        configData,
        tabLabels,
        setNotificationState,
        setEditState,
    };

}

export default useGetPrizeStateDetails;

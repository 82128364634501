import { useParams } from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from 'moment-timezone';
import { additionalDetailsValidationSchema } from "./AdditionalDetailsValidationSchema";
import { AdditionalDetailsForm } from './AdditionalDetailsForm';
import './AdditionalDetails.css';
import '../CommonStyles.css';
import { notificationType } from '../../../types/notifications';
import { additionalDetailsState } from '../../../types/componentTypes/additionalDetails';
import { ConfigType } from '../../../types/configuration';
import { saveConfig } from '../../../utils/s3FileUtils';
import { helpfulLinks } from "./constants/addtionalDetails-constants";

interface AdditionalInfoProps {
    config: ConfigType,
    setNotificationState: Dispatch<SetStateAction<notificationType>>,
    handleTabChange: Dispatch<SetStateAction<boolean>>,
    mechanic: string;
}

const initialValues = {
    minAgeChecked: false,
    minAge: undefined,
    queryVouchers: false,
    listPrizes: false,
    redeemPrize: false,
    addTransaction: false,
    redeemVoucher: false,
    queryWallet: false,
    useStatusReserved: 'false',
    userIdType: 'cds',
    teaser: false,
    visibleFromDate: moment().format('YYYY-MM-DD HH:mm'),
    tags: [],
    wv_url: '',
    addCaptcha: false,
    captchaSecret: '',
    maxParticipationIds: null,
    priorityOrder: ''
}



const AdditionalDetails = ({ config, setNotificationState, handleTabChange, mechanic }: AdditionalInfoProps) => {
    const [initialState, setInitialState] = useState<additionalDetailsState>(initialValues);
    const [promoFlow] = useState<string>(config.flow?.redeemPincodeForCurrencies ? 'redeemPincodeForCurrencies' : config.flow?.instantWin ? 'instantWin' : '');
    const [configData, setConfigData] = useState<ConfigType>(config);
    let urlParams = useParams();

    useEffect(() => {
        const includesCurrencyReducer = config.flow[promoFlow]?.flowLambdas?.includes('currencyReducer');
        const includesBurnPincodes = config.flow[promoFlow]?.flowLambdas?.includes('burnPincodes');
        let tags: string[] = [];

        if (includesCurrencyReducer && includesBurnPincodes && mechanic !== "Prize Draw (Lottery)" ) {
            tags = ['instantwinpe', 'instantwincost']
        }
        else if (mechanic === "Prize Draw (Lottery)" ) {
            tags = ['prizedrawlottery']
        }
        else if (includesBurnPincodes && !includesCurrencyReducer && mechanic === 'instant win') {
            tags = ['instantwinpe']
        }
        else if (mechanic === 'Collect & Get') {
            tags = ['C&G PE']
        }
        else if (includesCurrencyReducer && !includesBurnPincodes && mechanic !== "Prize Draw (Lottery)") {
            tags = ['instantwincost']
        }

        const updatedState = {
            redeemPrize: config.flow['redeemPrize'] ? true : false,
            addTransaction: config.flow['addTransaction'] ? true : false,
            redeemVoucher: config.flow['redeemVoucher'] ? true : false,
            queryWallet: config.flow['queryWallet'] ? true : false,
            minAgeChecked: (config.flow[promoFlow]?.params?.minAge || config.configurationParameters?.additionalInformation?.minAge) ? true : false,
            minAge: config.flow[promoFlow]?.params?.minAge ? config.flow[promoFlow].params.minAge : undefined,
            queryVouchers: !!config.flow?.queryVouchers,
            listPrizes: !!config.flow?.listPrizes,
            useStatusReserved: JSON.stringify(!!config.flow?.instantWin?.params?.useStatusReserved),
            userIdType: config.configurationParameters?.userIdType || 'cds',
            teaser: !!config.configurationParameters?.additionalInformation?.visibleFromDate,
            visibleFromDate: config.configurationParameters?.additionalInformation?.visibleFromDate
                ? moment(config.configurationParameters?.additionalInformation?.visibleFromDate).format('YYYY-MM-DD HH:mm')
                : config.configurationParameters?.configurationStartUtc
                    ? moment(config.configurationParameters?.configurationStartUtc).format('YYYY-MM-DD HH:mm')
                    : moment().format('YYYY-MM-DD HH:mm'),
            tags: config.configurationParameters?.additionalInformation?.tags ? Array.from(new Set([...tags, ...config.configurationParameters.additionalInformation.tags])) : [...tags],
            wv_url: config.configurationParameters?.additionalInformation?.wv_url || '',
            addCaptcha: !!config.configurationParameters?.captchaSecret,
            captchaSecret: config.configurationParameters?.captchaSecret || '',
            maxParticipationIds: config.flow?.acceptReservedVoucher?.params?.maxParticipationIds || null,
            priorityOrder: config.flow['listPrizes']?.params?.priorityOrder ? config.flow['listPrizes']?.params?.priorityOrder : 'ASC',
            imageEntry: config.flow?.promoEntry?.params?.imageEntry
        }
        setInitialState(updatedState)
    }, [config, promoFlow, mechanic])

    const onKeyDown = (event: any) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    }

    const handleConfigChanges = (values: additionalDetailsState) => {
        const updatedConfig:ConfigType = {
            ...configData,
            configurationParameters: {
                ...configData.configurationParameters,
                ...(values.userIdType) && { userIdType: values.userIdType },
                ...(values.addCaptcha) && { captchaSecret: values.captchaSecret },
                additionalInformation: {
                    ...configData.configurationParameters?.additionalInformation,
                    ...(values.teaser) && { visibleFromDate: moment(values.visibleFromDate).valueOf() },
                    ...(values.wv_url !== '') && { wv_url: values.wv_url },
                    ...(values.tags.length > 0) && { tags: values.tags },
                    ...(values.minAge) && { minAge: Number(values.minAge) }
                }
            },
            flow: {
                ...configData.flow,
                [promoFlow]: {
                    ...configData.flow[promoFlow],
                    params: {
                        ...configData.flow[promoFlow]?.params,
                        ...(promoFlow === 'instantWin' && JSON.parse(values.useStatusReserved)) && { useStatusReserved: JSON.parse(values.useStatusReserved) },
                        ...(values.minAge) && { minAge: Number(values.minAge) }
                    }
                },
                ...(JSON.parse(values.useStatusReserved)) && {
                    acceptReservedVoucher: {
                        flowLambdas: ["acceptReservedVoucherLambda"],
                        params: {
                            maxParticipationIds: +values.maxParticipationIds
                        }
                    }
                }
            }
        }

        if (promoFlow === 'redeemPincodeForCurrencies' && values.minAge && updatedConfig.flow[promoFlow].params?.minAge) {
            updatedConfig.flow[promoFlow].checkerLambdas ? updatedConfig.flow[promoFlow].checkerLambdas.push("age") : Object.assign(updatedConfig.flow[promoFlow], { checkerLambdas: ["age"] } );
        }
        if (promoFlow === 'redeemPincodeForCurrencies' && !values.minAgeChecked && (updatedConfig.flow[promoFlow].params?.minAge)) {
            if(updatedConfig.flow[promoFlow].checkerLambdas) {
                updatedConfig.flow[promoFlow].checkerLambdas = updatedConfig.flow[promoFlow].checkerLambdas.filter(item => item !== 'age');
                if(!updatedConfig.flow[promoFlow].checkerLambdas.length) delete updatedConfig.flow[promoFlow].checkerLambdas;
            }
            delete updatedConfig.flow[promoFlow].params?.minAge
            delete updatedConfig.configurationParameters?.additionalInformation.minAge

        }

        if (values.wv_url === '' && updatedConfig.configurationParameters?.additionalInformation?.wv_url) {
            delete updatedConfig.configurationParameters?.additionalInformation?.wv_url
        }

        setConfigData(updatedConfig)
        return updatedConfig
    }

    return (
        <>
            <Box className='edit-page-wrapper'>
                <Typography variant='h3'>
                    Additional Details
                </Typography>
            </Box>
            <Formik
                initialValues={initialState}
                enableReinitialize={true}
                validationSchema={additionalDetailsValidationSchema}
                onSubmit={async (values) => {
                    try {
                        const updatedValues = handleConfigChanges(values);
                        await saveConfig({ submitData: updatedValues, urlParams, setNotificationState });
                        setNotificationState({
                            open: true,
                            title: 'Promotion updated successfully',
                            content: 'Promotion Changes were saved!',
                            level: "success"
                        })
                    } catch (e) {
                        console.error(e);
                    }
                }}
            >
                <Form onKeyDown={onKeyDown}>
                    <AdditionalDetailsForm configData={configData} setConfigData={setConfigData} handleTabChange={handleTabChange} promoFlow={promoFlow} mechanic={mechanic} />
                </Form>
            </Formik>
            <Typography variant="h3">Helpful Links</Typography>
            <Box className='helpfulLinks'>
                {helpfulLinks[mechanic].map((helpfulLink, i) =>
                    <Link key={i} target='_blank' href={helpfulLink.link} rel="noopener noreferrer">{helpfulLink.text}</Link>
                )}
            </Box>
        </>
    )
}

export { AdditionalDetails }

import { Box, Typography, Link } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { MixCodesForm } from './MixCodesForm';
import { ensureOrderOfFlowLambdas } from '../../AssignPromotion/AssignPromotionPage';
import { mixcodesValidationSchema } from './MixCodesValidationSchema';
import { notificationType } from '../../../types/notifications';
import './MixCodes.css';
import '../CommonStyles.css';
import { saveConfig } from '../../../utils/s3FileUtils';
import { mixCodes } from '../../../constants/helpful-link';

interface props {
    config: { [key: string]: any };
    setNotificationState: Dispatch<SetStateAction<notificationType>>;
    handleTabChange: Dispatch<SetStateAction<boolean>>;
}

const initialValues = {
    tempParams: {
        burnPincodes: false,
        codeType: [],
        pincodeOriginValitidy: false,
    },
    params: {
        lotIds: [''],
        campaignIds: [''],
    },
    secrets: {
        mixCodesParameters: [{ programId: '', secret: '', uri: '' }],
    },
    checkerLambdas: [],
    flowLambdas: [],
};

const MixCodes = ({ config, setNotificationState, handleTabChange }: props) => {
    const [initialState, setInitialState] = useState(initialValues);
    const [promoFlow, setPromoFlow] = useState<string>('');
    let urlParams = useParams();

    useEffect(() => {
        if (config.flow?.redeemPincodeForCurrencies) {
            setPromoFlow('redeemPincodeForCurrencies');
        }
        if (config.flow?.instantWin) {
            setPromoFlow('instantWin');
        }
        if (config.flow?.promoEntry) {
            setPromoFlow('promoEntry');
        }
        if (config.flow?.autoRedeemCnG) {
            setPromoFlow('autoRedeemCnG');
        }
    }, [config.flow]);

    useEffect(() => {
        let updatedState = {
            ...config,
            tempParams: {
                burnPincodes: !!(
                    config.flow[promoFlow]?.flowLambdas && config.flow[promoFlow].flowLambdas.includes('burnPincodes')
                ),
                codeType:
                    (config.flow[promoFlow]?.secrets?.mixCodesParameters &&
                        config.flow[promoFlow].secrets?.mixCodesParameters.map((obj: any) => {
                            if (obj.uri.includes('pincodes')) {
                                return 'Pincodes';
                            }
                            return 'Viral Codes';
                        })) ||
                    [],
                pincodeOriginValitidy: !!(
                    config.flow[promoFlow]?.checkerLambdas &&
                    config.flow[promoFlow].checkerLambdas.includes('pincodeOriginValidityCheckerLambda')
                ),
            },
            params: {
                lotIds: config.flow[promoFlow]?.params?.lotIds || [''],
                campaignIds: config.flow[promoFlow]?.params?.campaignIds || [''],
            },
            secrets: {
                ...(config.flow[promoFlow]?.secrets?.mixCodesParameters
                    ? { mixCodesParameters: [...config.flow[promoFlow]?.secrets?.mixCodesParameters] }
                    : { mixCodesParameters: [...initialValues.secrets.mixCodesParameters] }),
            },
            checkerLambdas: config.flow[promoFlow]?.checkerLambdas || [],
            flowLambdas: config.flow[promoFlow]?.flowLambdas || [],
        };
        setInitialState(updatedState);
    }, [config, promoFlow]);

    const cleanPincodeValidatorDetails = (params) => {
        for (const key of ['lotIds', 'campaignIds']) {
            if (params[key]?.length === 1 && params[key][0] === '') delete params[key];
        }
    }

    const handleSubmitValues = (values: any) => {
         let { checkerLambdas, flowLambdas, params, secrets, tempParams, ...rest } = values;

        if (!tempParams.burnPincodes) {
            checkerLambdas = checkerLambdas.filter((lambda: string) => lambda !==  'pincodeOriginValidityCheckerLambda')
            rest.flow[promoFlow].checkerLambdas = values.flow[promoFlow]?.checkerLambdas?.filter((lambda: string) => lambda !==  'pincodeOriginValidityCheckerLambda')
            delete values.flow[promoFlow]?.secrets;
        }

        if (!tempParams.pincodeOriginValitidy || tempParams.pincodeOriginValitidy === '') {
            checkerLambdas = checkerLambdas.filter((lambda: string) => lambda !==  'pincodeOriginValidityCheckerLambda')
            rest.flow[promoFlow].checkerLambdas = values.flow[promoFlow]?.checkerLambdas?.filter((lambda: string) => lambda !==  'pincodeOriginValidityCheckerLambda')
            if (!(checkerLambdas.length > 0)) {
            delete rest.flow[promoFlow].checkerLambdas
            }
            delete values.flow[promoFlow]?.params?.lotIds;
            delete values.flow[promoFlow]?.params?.campaignIds;
        }

        cleanPincodeValidatorDetails(params);

        let updatedValues = {
            ...rest,
            flow: {
                ...rest.flow,
                [promoFlow]: {
                    ...rest.flow[promoFlow],
                    ...(tempParams.burnPincodes && { secrets }),
                    flowLambdas: ensureOrderOfFlowLambdas(flowLambdas),
                    ...(checkerLambdas.length > 0 && { checkerLambdas }),
                    params: {
                        ...rest.flow[promoFlow]?.params,
                        ...(params.lotIds && params.lotIds.length > 0 && {lotIds: [...params.lotIds]}),
                        ...(params.campaignIds && params.campaignIds.length > 0 && {campaignIds: [...params.campaignIds]}),
                    },
                },
            },
        };
        return updatedValues;
    };

    return (
        <Box className='mixcodes-main-box'>
            <Box className='mixcodes-tap-wrapper'>
                <Typography variant='h3'>MiX Codes</Typography>
                <Typography variant='body1'>
                    Enter MiX Codes details if pin codes and/or viral codes are required for participation in this
                    promotion
                </Typography>
            </Box>
            <Formik
                initialValues={initialState}
                enableReinitialize
                validationSchema={mixcodesValidationSchema}
                onSubmit={async (values) => {
                    try {
                        const updatedConfig = handleSubmitValues(values);
                        await saveConfig({ urlParams, submitData: updatedConfig, setNotificationState });
                        setNotificationState({
                            open: true,
                            title: 'Promotion updated successfully',
                            content: 'Promotion Changes were saved!',
                            level: 'success',
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }}>
                <Form>
                    <MixCodesForm handleTabChange={handleTabChange} />
                </Form>
            </Formik>

            <Typography variant='h3' className='links-title'>
                Helpful Links
            </Typography>
            <Box className='helpfulLinks'>
                    <Link target='_blank' href={mixCodes.MIX_CODE_INTEGRATION} rel='noopener noreferrer'>
                        MiX Codes NGPS Integration
                    </Link>
            </Box>
        </Box>
    );
};

export { MixCodes };

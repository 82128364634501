import { Box, Typography, Stepper, Step, StepLabel } from "@mui/material";
import type { AlertColor } from '@mui/material';
import { Formik, FormikContextType } from "formik";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getCurrencyTable } from '../../graphql/queries';
import { createCurrencyAllocationRulesTable, updateCurrencyAllocationRulesTable } from '../../graphql/mutations';
import { AddAllocationRulesForm } from "./AddAllocationRulesForm";
import { addAllocationRules, addAllocationRulesProps, allocationRules } from "../../types/componentTypes/allocationRules";
import './AllocationRules.css'
import { allocationRulesValidationSchema } from "./AllocationRulesValidationSchema";
import { notificationType } from "../../types/notifications";
import { GetCurrencyTableResult, CurrencyTableItem } from "../../types/componentTypes/CurrencyTypes";
import { Notification } from '../../common/Notification/Notification';

const steps = [
    'MixCodes Details',
    'Currency Details',
];
const initialValues: addAllocationRules = {
    configuration_id: '',
    tempParams: {
        ruleFor: 'lotId',
        marketCurrencies: []
    },
    currency_id: '',
    amount: null,
    program_id: null,
    lot_id: null,
    campaign_id: null,
    validity: null,
    rule_active: true
}
function AddAllocationRulesPage({ configurationId, configMarket, state, setShowAllocationRulePage }: addAllocationRulesProps) {
    const [initialState, setInitialState] = useState<addAllocationRules>(initialValues)
    const [step, setStep] = useState<number>(0);
    const [editState, setEditState] = useState<allocationRules | undefined>(state);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [notificationState, setNotificationState] = useState<notificationType>({ open: false, title: '', content: '', level: "success" as AlertColor });

    const updateStep = (num: number) => {
        const newStep = step + num
        setStep(newStep);
    }
    useEffect(() => {

        const getCurrenciesData = async () => {
            try {
                const currenciesData = await API.graphql({ query: getCurrencyTable }) as GraphQLResult<GetCurrencyTableResult>;
                return currenciesData.data.getCurrencyTable.items;
            } catch (e) {
                console.log(e)
            }
        }

        const ruleFor = (editState?.lot_id && editState?.lot_id !== null && editState?.lot_id !== "*" && editState?.lot_id.toString().length > 3)
            ? 'lotId'
            : (editState?.lot_id && editState?.lot_id === "*")
                ? 'all'
                : 'campaignId';

        getCurrenciesData()
            .then(data => {
                const updatedValues = {
                    ...(editState ? {
                        ...editState, campaign_id: ruleFor === 'campaignId' ? editState.lot_id : null, tempParams: {
                            ruleFor: ruleFor,
                            marketCurrencies: data.filter((currency: CurrencyTableItem) => currency.country === configMarket).map(((obj: {currency_id:string }) => obj.currency_id)) || []
                        }
                    } : { ...initialValues, tempParams: {
                        ...initialValues.tempParams,
                        marketCurrencies : data.filter((currency: CurrencyTableItem) => currency.country === configMarket).map(((obj: {currency_id:string }) => obj.currency_id)) || []
                        }
                    }),
                    configuration_id: configurationId,
                }
                setInitialState(updatedValues);
            })

        editState ? setIsEdit(true) : setIsEdit(false);
    }, [configurationId, editState, configMarket])

    const adjustTempValues = (values: addAllocationRules) => {
        const { tempParams, ...mainValues } = values;

        if(!editState && mainValues.validity === '') {
            delete mainValues.validity
        } else if(editState && mainValues.validity === '') {
            mainValues.validity = null
        }

        if (tempParams.ruleFor === 'all') {
            delete mainValues.campaign_id;
            mainValues.lot_id = '*';
        } else if (tempParams.ruleFor === 'lotId') {
            delete mainValues.campaign_id
        } else if (tempParams.ruleFor === 'campaignId') {
            mainValues.lot_id = mainValues.campaign_id;
            delete mainValues.campaign_id
        }
        return mainValues
    }

    const concatenateColumnValues = (...column: any) => `${column.join('|')}`;


    return (
        <>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
            <Box className='allocation-rules-box-main'>
                <Typography variant='h4' gutterBottom>Add Currency Allocation Rule</Typography>
                {step === 0 && <>
                    <Typography variant='h4' gutterBottom className="titles-style">MiXCodes Details</Typography>
                    <Typography variant='body1' gutterBottom>If different currency amount needs to be allocated depending on certain Lot or Campaign within the same Program, a separate rule must be created for each Lot or Campaign.</Typography>
                </>}
                {step === 1 && <Typography variant='h4' gutterBottom className="titles-style">Currency Details</Typography>}
                <Box>
                    <Formik
                        initialValues={initialState}
                        enableReinitialize={true}
                        validationSchema={allocationRulesValidationSchema()}
                        onSubmit={async (values) => {
                            try {
                                const rule = adjustTempValues(values);
                                const ruleId = concatenateColumnValues(rule.program_id, rule.lot_id, rule.currency_id);
                                const insertParams = {
                                    ...rule,
                                    rule_id: ruleId,
                                    rule_active: true
                                }
                                editState
                                    ? await API.graphql(graphqlOperation(updateCurrencyAllocationRulesTable, { input: rule }))
                                    : await API.graphql(graphqlOperation(createCurrencyAllocationRulesTable, { input: insertParams }))
                                updateStep(1)
                            } catch (e) {
                                console.error("Save failed with: ", e);
                                setNotificationState({
                                    open: true,
                                    title: 'Error',
                                    content: `Rule creation failed! Please check console for detailed error.`,
                                    level: "error"
                                })
                            }
                        }}
                    >
                        {(formik: FormikContextType<addAllocationRules>) => (
                            <form onSubmit={formik.submitForm}>
                                <AddAllocationRulesForm updateStep={updateStep} step={step} setShowAllocationRulePage={setShowAllocationRulePage} setReset={setEditState} isEdit={isEdit} />
                            </form>
                        )}
                    </Formik>
                    <Stepper activeStep={step} alternativeLabel className='stepper-size'>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </Box>
        </>
    )
}

export { AddAllocationRulesPage }

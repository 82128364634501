import moment from 'moment-timezone';
import { Storage } from 'aws-amplify';
import { Params } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { notificationType } from '../types/notifications';
import { ConfigType } from '../types/configuration';

Storage.configure({
    customPrefix: {
        public: '',
        protected: '',
        private: '',
    },
});

const getConfig = async (fileName: string) => {
    try {
        const s3Response = await Storage.get(fileName, { download: true, cacheControl: 'no-cache' });
        if (s3Response?.Body && s3Response.Body instanceof Blob) {
            const response = new Response(s3Response.Body);
            return (await response.json()) as ConfigType;
        }
    } catch (error) {
        console.error(error);
    }
};

const saveConfig = async ({
    urlParams,
    submitData,
    setNotificationState,
}: {
    urlParams: Readonly<Params<string>>;
    submitData: unknown;
    setNotificationState: Dispatch<SetStateAction<notificationType>>;
}) => {
    const fileName = `${urlParams.promotionId}/conf.txt`;
    const replaceName = `${urlParams.promotionId}/conf_last_used_${moment().valueOf()}.txt`;
    try {
        const responseBody = await getConfig(fileName);

        await Storage.put(replaceName, JSON.stringify(responseBody), { contentType: 'text/plain' });
        await Storage.put(fileName, JSON.stringify(submitData), {
            contentType: 'text/plain',
        });
    } catch (e) {
        setNotificationState({
            open: true,
            title: 'Promotion not updated',
            content: 'Promotion update failed!',
            level: 'error',
        });
        console.error('Saving config to s3 failed with: ', e);
    }
};

export { saveConfig, getConfig };

import * as yup from 'yup';

interface TestContextExtended {
    [key: string]: any;
}

function additionalDetailsValidationSchema() {
    return yup.object({
        tags: yup.array()
            .test("tagsTest", "Tags field cannot contain more than 10 items",
                (value) => {
                    let result = true
                    if (value && value?.length > 10) {
                        result = false
                    }
                    return result
                })
            .test("tagsTest", "Tags field can contain only alphanumeric values",
                (value) => {
                    const alphaNumericRegex = /^[a-zA-Z0-9]+/
                    let result = true
                    if (value && value.some(v => !v.match(alphaNumericRegex))) {
                        result = false
                    }
                    return result
                }),
        captchaSecret: yup.mixed()
            .test('testcaptcha', "If option selected, captcha secret has to be provided",
                (value, testContext) => {
                    const { parent } = testContext as yup.TestContext & TestContextExtended;
                    if (parent.addCaptcha && (value === '' || !value)) {
                        return false
                    }
                    return true
                }),
        minAgeChecked: yup.boolean(),
        minAge: yup.number()
            .when('minAgeChecked', {
                is: true,
                then: yup.number()
                    .typeError('The value must be a number')
                    .required('If the option selected, Minimum age limit that is a number has to be provided')
                    .positive('The value must be a positive number')
            }),
        maxParticipationIds: yup.number().nullable(true).max(100).min(1).label('Maximum vouchers')
    })
}
export { additionalDetailsValidationSchema }

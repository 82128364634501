import { Box, Typography } from '@mui/material';
import { NavBar } from '../../common/NavBar/NavBar'
import { useState, SyntheticEvent } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { PrizeLocalization } from './PrizeLocalization';
import { AddToPrizeLanguageTabs } from '../../components/modals/AddToPrizeLanguageTabs';
import { getIn } from 'formik';
import { languageCodes } from '../../constants/lists';

interface PrizeLocalizationProps {
    formik: any,
    tabs: string[],
    backstepDone?: boolean,
    isEdit?: boolean
}
function PrizeTabSwitch({ formik, tabs, backstepDone, isEdit }: PrizeLocalizationProps) {
    const [open, setOpen] = useState(false);
    const [customIndex, setCustomIndex] = useState<number | null>(1);
    const [languageIndex, setLanguageIndex] = useState<number>(0);
    const handleModalOpen = (event: SyntheticEvent, index: number) => {
        if (index === 0) {
            setOpen(true)
        }
    }

    const handleTabDeleteFromFormikState = (language?: string): void => {
        const stateValues: string[] = ['name', 'desc', 'short_desc', 'redeem_desc'];
        let languageCode = Object.keys(languageCodes).find(key => languageCodes[key] === language);
        let indexToDelete: number;
        stateValues.forEach((value)=> {
            formik.values[value].forEach((innerValue, index) => {
                if (innerValue.hasOwnProperty(languageCode)) {
                    indexToDelete = index;
                }
            })
        formik.values[value].splice(indexToDelete, 1);
        })
    };

    const tabComponents: Array<ReactJSXElement> = [];
    tabs.forEach((value: string, index: number) => {
        if (value.toLocaleLowerCase().includes('add')) {
            tabComponents.splice(0, 0, <AddToPrizeLanguageTabs modalOpenState={open} listToInsertTo={tabs} country={getIn(formik.values, 'tempParams.country')} handleClose={setOpen} handleCustomIndex={setCustomIndex}/>);
        } else {
            const code = Object.keys(languageCodes).find(key => languageCodes[key] === value) || 'en'
            tabComponents.splice(index , 0, <PrizeLocalization backstepDone={backstepDone} isEdit={isEdit} formik={formik} language={code} setLanguageIndex={setLanguageIndex} languageIndex={languageIndex} />)
        }
    })
    return (
        <Box className='prize-box-main'>
            <Typography variant='h4' gutterBottom>Prize Localization</Typography>
            <Typography variant='body1' gutterBottom>Provide localized texts for every applicable language</Typography>
            <NavBar enableDirectionSelectArrows={true} deleteTabButtonCustomAction={handleTabDeleteFromFormikState}  enableTabRemove={true} tabLabels={tabs} customAction={handleModalOpen} components={tabComponents} navClass='nav-tabs' customTabIndex={customIndex} resetCustomIndex={setCustomIndex} />
        </Box>
    )
}

export { PrizeTabSwitch }

import { Box } from '@mui/material';
import { InputComponent } from '../../components/inputComponents/InputComponent';
import { FileUpload } from '../inputComponents/FileUpload/FileUpload.component';
import { TooltipText } from '../../components/inputComponents/TooltipText';
import { useEffect, useState } from 'react';
import { getIn } from 'formik';
import { defaultNotifState } from '../../constants/currency-constants';
import { Notification } from '../../common/Notification/Notification';
import awsconfig from '../../aws-exports';

interface PrizeLocalizationProps {
    formik: any;
    language: string;
    backstepDone?: boolean;
    isEdit?: boolean;
    languageIndex?: number;
    setLanguageIndex?: (value: number) => void;
}

function PrizeLocalization({
    formik,
    language,
    backstepDone,
    isEdit,
    languageIndex,
    setLanguageIndex,
}: PrizeLocalizationProps) {

    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const bucketName = awsconfig.aws_user_files_s3_bucket;
    const env = bucketName.split('-').pop();
    const envPathNaming = env === "prod" ? "global." : (env === "production" || env === "production-it") ? "" : `${env}.`;
    const prizeImagePath = `https://public.${envPathNaming}ngps.coca-cola.com/prizeImages/${formik.values.configuration_id}/${formik.values.prize_id}/`;
    useEffect(() => {
        const stateValues: string[] = ['name', 'desc', 'short_desc', 'redeem_desc'];
        formik.values.name.forEach((value: string, index: number) => {
            if (value.hasOwnProperty(language)) {
                setLanguageIndex(index);
            }
        });
        stateValues.forEach((value: string) => {
            let languages: string[] = formik.values[value].flatMap((langKeyValuePair) => {
                return Object.keys(langKeyValuePair);
            });
            if (!languages.includes(language)) {
                formik.setFieldValue(`${value}[${languages.length}]`, { [language]: '' });
            }
        });
    }, [formik, language, formik.values, languageIndex, setLanguageIndex]);

    return (
        <Box className='prize-box-content'>

            <Notification
                notificationState={notificationState}
                setNotificationState={setNotificationState}
                messageVisibilityTime={2000}
            />
            <TooltipText
                styleName='prize-tooltip-text'
                text='Prize Name'
                textVariant='body1'
                tooltip="Make sure this prize name doesn't exist already under this promotion to avoid any potential confusion"
                iconSize='medium'
            />
            <InputComponent
                inputClassName='input-space'
                formik={formik}
                inputValue={getIn(formik.values, `name[${languageIndex}][${language}]`)}
                formikLabel={`name[${languageIndex}][${language}]`}
                placeholder='Up to 90 characters'
                errorPath={'name'}
            />
            <TooltipText
                styleName='prize-tooltip-text'
                text='Prize Image'
                textVariant='body1'
                tooltip='Recommended image resolution: 567 x 567 px. Allowed image formats: PNG, JPG, SVG'
                iconSize='medium'
            />
            <FileUpload
                backstepDone={backstepDone}
                isEdit={isEdit}
                cachedFiles={formik.values.tempParams.img_url?.map((img: string) => ({ name: img })) || []}
                uploadCallback={(files: Array<String>) => {
                    formik.setFieldValue('img_url', [(`${prizeImagePath}${files}` || []) ]);
                    formik.setFieldValue('tempParams.img_url', [(`${prizeImagePath}${files}` || [])]);
                }}
                deleteCallback={(file: string) => {
                    formik.setFieldValue(
                        'tempParams.img_url',
                        formik.values.tempParams.img_url?.filter((img: string) => img !== file)
                    );
                    formik.setFieldValue(
                        'img_url',
                        formik.values.img_url?.filter((img: string) => img !== file)
                    );
                }}
                placeholder={'No file chosen'}
                bucket='public'
                filePath={`prizeImages/${formik.values.configuration_id}/${formik.values.prize_id}`}
                maxFiles={5}
                usePublicDomain={true}
                customAllowedFileTypes={['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml']}
                setNotificationState={setNotificationState}
            />
            <InputComponent
                minRows={3}
                inputClassName='input-space'
                inputPropsClass='prize-localization-input'
                formik={formik}
                inputValue={getIn(formik.values, `desc[${languageIndex}][${language}]`)}
                formikLabel={`desc[${languageIndex}][${language}]`}
                placeholder='Up to 1000 characters'
                title='Long Description'
                titleVariant='body1'
                errorPath='desc'
                multiline={true}
            />
            <InputComponent
                minRows={3}
                inputClassName='input-space'
                inputPropsClass='prize-localization-input'
                formik={formik}
                inputValue={getIn(formik.values, `short_desc[${languageIndex}][${language}]`)}
                formikLabel={`short_desc[${languageIndex}][${language}]`}
                placeholder='Up to 250 characters'
                title='Short Description'
                titleVariant='body1'
                errorPath='short_desc'
                multiline={true}
            />
            <TooltipText
                styleName='prize-tooltip-text'
                text='Prize Redemption Instructions'
                textVariant='body1'
                tooltip='Enter the terms and conditions on where and by when the winner can redeem the prize, if a specific voucher is required, etc.'
                iconSize='medium'
            />
            <InputComponent
                minRows={3}
                inputClassName='input-space'
                inputPropsClass='prize-localization-input'
                formik={formik}
                inputValue={getIn(formik.values, `redeem_desc[${languageIndex}][${language}]`)}
                formikLabel={`redeem_desc[${languageIndex}][${language}]`}
                placeholder='Up to 1000 characters'
                errorPath='redeem_desc'
                multiline={true}
            />
            <TooltipText
                styleName='prize-tooltip-text'
                text='Redemption URL'
                textVariant='body1'
                tooltip='Is there a URL consumer should go to redeem the voucher or an email address to contact?'
                iconSize='medium'
            />
            <InputComponent
                inputClassName='input-space'
                formik={formik}
                inputValue={getIn(formik.values, 'redemption_link')}
                formikLabel={'redemption_link'}
                placeholder='https://example.com or example@mail.com'
            />
        </Box>
    );
}

export { PrizeLocalization };

import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { CheckboxComponent } from '../../../components/inputComponents/CheckboxComponent';
import { RadioButton } from '../../../components/inputComponents/RadioButton';
import { TooltipText } from '../../../components/inputComponents/TooltipText';
import { InputComponent } from '../../../components/inputComponents/InputComponent';
import { Box, Typography, Button } from '@mui/material';
import { getIn, useFormikContext } from 'formik';
import { useEffect, useState, ChangeEvent } from 'react';
import { additionalDetailsState } from '../../../types/componentTypes/additionalDetails';
import { DetailsFormProps } from '../../../types/componentTypes/editPromotion';
import { MultipleSelectOptions } from '../../../components/selectComponents/MultipleSelectOptions';
import { AddToListModal } from '../../../components/modals/AddToListModal';
import { InputNumberSpinner } from '../../../components/inputComponents/InputNumberSpinner';
import * as yup from 'yup';
import TextFieldWrapper from '../../../common/TextFieldWrapper/TextFieldWrapper';

const tags: any = [];

const tagsValidationSchema = yup.object({
    tag: yup
        .string()
        .matches(/.*/)
        .test(
            'Max length',
            'Max length is 250 characters',
            (val: any) => val && val.toString().length <= 250
        )
        .required('Field is required'),
});

const AdditionalDetailsForm = ({
    configData,
    setConfigData,
    handleTabChange,
    promoFlow,
    mechanic,
}: DetailsFormProps) => {
    const formik = useFormikContext<additionalDetailsState>();
    const [open, setOpen] = useState(false);

    const flowMap = {
        queryVouchers: {
            flowLambdas: ['digitalCodesQueryByUserLambda'],
        },
        listPrizes: {
            flowLambdas: [
                'prizeQueryLambda',
            ],
            params: {
                priorityOrder: formik.values.priorityOrder,
                additionalInformation: true,
            },
        },
        redeemPrize: {
            checkerLambdas: ['prizeCheckerLambda', 'currencyCheckerLambda'],
            flowLambdas: ['prizeRedeemLambda'],
        },
        addTransaction: {
            flowLambdas: ['transactionLambda'],
        },
        redeemVoucher: {
            flowLambdas: ['digitalCodesRedeemLambda'],
        },
        queryWallet: {
            flowLambdas: ['walletLambda'],
        },
    };

    const handleOpen = () => setOpen(true);

    useEffect(() => {
        handleTabChange(formik.dirty && formik.submitCount === 0);
    }, [formik.dirty, formik.submitCount, handleTabChange]);

    const handleTeaserCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        if (!checked) {
            formik.setFieldValue(
                'visibleFromDate',
                moment().format('YYYY-MM-DD HH:mm')
            );
            if (
                configData.configurationParameters?.additionalInformation
                    ?.visibleFromDate
            ) {
                let updatedConfigData = { ...configData };
                delete updatedConfigData.configurationParameters?.additionalInformation
                    ?.visibleFromDate;
                setConfigData(updatedConfigData);
            }
        }
    };

    const handleCaptchaCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        if (!checked) {
            formik.setFieldValue('captchaSecret', '');
            if (configData.configurationParameters?.captchaSecret) {
                let updatedConfigData = { ...configData };
                delete updatedConfigData.configurationParameters?.captchaSecret;
                setConfigData(updatedConfigData);
            }
        }
    };

    const handleMinAgeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        if (!checked) {
            formik.setFieldValue('minAge', '');
            if (configData.configurationParameters?.additionalInformation?.minAge) {
                let updatedConfigData = { ...configData };
                delete updatedConfigData.configurationParameters?.additionalInformation
                    .minAge;
                setConfigData(updatedConfigData);
            }
        }
    };

    const handleImagePromotionEntry = (event: ChangeEvent<HTMLInputElement>)=> {
        const checked = event.target.checked;
        if (!checked) {
            formik.setFieldValue('params.imageEntry', false);
            if (configData.flow?.promoEntry?.params?.imageEntry) {
                let updatedConfigData = { ...configData };
                delete updatedConfigData.flow?.promoEntry?.params?.imageEntry;
                setConfigData(updatedConfigData);
            }
        }
        if (checked) {
            formik.setFieldValue('params.imageEntry', true);
            let updatedConfigData = { ...configData };
            updatedConfigData.flow.promoEntry.params.imageEntry = true;
            setConfigData(updatedConfigData);
        }
    };

    const handleConfigFlow = (event: any) => {
        const name: string = event.target.name;
        const checked: boolean = event.target.checked;

        if (name === 'priorityOrder') {
            let updatedConfigData = { ...configData };
            updatedConfigData.flow.listPrizes.params.priorityOrder =
                event.target.value;
            setConfigData(updatedConfigData);
        } else if (checked && !configData.flow?.[name]) {
            let updatedConfigData = {
                ...configData,
                flow: {
                    ...configData.flow,
                    [name]: flowMap[name as keyof typeof flowMap],
                },
            };
            setConfigData(updatedConfigData);
        } else if (!checked && configData.flow?.[name]) {
            let updatedConfigData = { ...configData };
            delete updatedConfigData.flow[name];
            setConfigData(updatedConfigData);
        }
    };

    const handleInterruptedFlow = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'true') {
            formik.setFieldValue('maxParticipationIds', 10);
            const updatedConfig = {
                ...configData,
                flow: {
                    ...configData.flow,
                    rejectWinningMoment: {
                        flowLambdas: ['rejectWinningMomentLambda'],
                    },
                    acceptReservedVoucher: {
                        flowLambdas: ['acceptReservedVoucherLambda'],
                    },
                    rejectReservedVoucher: {
                        flowLambdas: ['rejectReservedVoucherLambda'],
                    },
                },
            };
            setConfigData(updatedConfig);
        } else {
            formik.setFieldValue('maxParticipationIds', null);
            const updatedConfig = { ...configData };
            const flowsToDelete = [
                'rejectWinningMoment',
                'acceptReservedVoucher',
                'rejectReservedVoucher',
            ];
            flowsToDelete.map((flow) => delete updatedConfig.flow[flow]);
            delete updatedConfig.flow?.instantWin?.params?.useStatusReserved;
            setConfigData(updatedConfig);
        }
    };

    return (
        <>
            <Box className="containerDiv">
                <Box className="leftChildDiv">
                    {mechanic === 'Collect & Get' && (
                        <>
                            <Typography variant="body1">
                                Select functionalities required for this promotion
                            </Typography>
                            <CheckboxComponent
                                fieldName={'listPrizes'}
                                label={
                                    <TooltipText
                                        styleName="details-checkbox-tooltip"
                                        text="Prize Catalog (Optional)"
                                        textVariant="body1"
                                        tooltip={
                                            'Once enabled, prize catalog can be shown to participants'
                                        }
                                    />
                                }
                                customAction={handleConfigFlow}
                            />
                            {formik.values.listPrizes && (
                                <Box>
                                    <Typography variant="body1">Prize List Priority</Typography>
                                    <RadioButton
                                        formik={formik}
                                        customAction={handleConfigFlow}
                                        values={[
                                            { value: 'ASC', label: 'Ascending' },
                                            { value: 'DESC', label: 'Descending' },
                                        ]}
                                        radioGroupName={'priorityOrder'}
                                    />
                                </Box>
                            )}
                            <CheckboxComponent
                                fieldName={'redeemPrize'}
                                label={
                                    <TooltipText
                                        styleName="details-checkbox-tooltip"
                                        text="Prize Redemption (Optional)"
                                        textVariant="body1"
                                        tooltip={
                                            'Once enabled, participants can redeem prizes even if no vouchers are distributed'
                                        }
                                    />
                                }
                                customAction={handleConfigFlow}
                            />
                            <CheckboxComponent
                                fieldName={'addTransaction'}
                                label={
                                    <TooltipText
                                        styleName="details-checkbox-tooltip"
                                        text="Transactions (Optional)"
                                        textVariant="body1"
                                        tooltip={
                                            "Once enabled, the amount of every participant's wallet can be modified"
                                        }
                                    />
                                }
                                customAction={handleConfigFlow}
                            />
                            <CheckboxComponent
                                fieldName={'queryVouchers'}
                                label={
                                    <TooltipText
                                        styleName="details-checkbox-tooltip"
                                        text="Vouchers History (Optional)"
                                        textVariant="body1"
                                        tooltip={
                                            'Once enabled, vouchers history can be shown to participants'
                                        }
                                    />
                                }
                                customAction={handleConfigFlow}
                            />
                            <CheckboxComponent
                                fieldName={'redeemVoucher'}
                                label={
                                    <TooltipText
                                        styleName="details-checkbox-tooltip"
                                        text="Vouchers Redemption (Optional)"
                                        textVariant="body1"
                                        tooltip={
                                            'This functionality gives participants the ability to redeem vouchers for prizes'
                                        }
                                    />
                                }
                                customAction={handleConfigFlow}
                            />
                            <CheckboxComponent
                                testingId={'queryWalletTestId'}
                                fieldName={'queryWallet'}
                                label={
                                    <TooltipText
                                        styleName="details-checkbox-tooltip"
                                        text="Wallet (Optional)"
                                        textVariant="body1"
                                        tooltip={
                                            'Once enabled, participants will be able to see their wallets'
                                        }
                                    />
                                }
                                customAction={handleConfigFlow}
                            />
                            <CheckboxComponent
                                fieldName={'teaser'}
                                label={
                                    <TooltipText
                                        styleName="details-checkbox-tooltip"
                                        text="Promotion Тeaser"
                                        textVariant="body1"
                                        tooltip="Allows you to enable a promotion teaser before the start date to build consumers' awareness"
                                    />
                                }
                                customAction={handleConfigFlow}
                            />
                            {formik.values.teaser && (
                                <Box sx={{ width: '30%' }}>
                                    <Typography>Visible From</Typography>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        locale="en-US"
                                    >
                                        <DesktopDateTimePicker
                                            value={moment(formik.values.visibleFromDate)}
                                            ampm={false}
                                            minDate={moment(new Date())}
                                            maxDate={
                                                configData.configurationParameters
                                                    ?.configurationStartUtc
                                                    ? moment(
                                                        configData.configurationParameters
                                                            ?.configurationStartUtc
                                                    )
                                                    : undefined
                                            }
                                            onChange={(date) =>
                                                formik.setFieldValue(
                                                    'visibleFromDate',
                                                    date?.format('YYYY-MM-DD HH:mm')
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextFieldWrapper
                                                    className="input-text"
                                                    onKeyDown={(e: any) => e.preventDefault()}
                                                    {...params}
                                                />
                                            )}
                                        ></DesktopDateTimePicker>
                                    </LocalizationProvider>
                                </Box>
                            )}
                            <CheckboxComponent
                                fieldName={'addCaptcha'}
                                label={
                                    <Typography variant="body1" className="tags-title">
                                        Web Integration
                                    </Typography>
                                }
                                customAction={handleCaptchaCheckbox}
                            />
                            {formik.values.addCaptcha && (
                                <InputComponent
                                    formik={formik}
                                    inputValue={getIn(formik.values, 'captchaSecret')}
                                    formikLabel={'captchaSecret'}
                                    title={'Captcha Secret'}
                                    titleVariant={'body1'}
                                    inputClassName="captchaTxt"
                                    multiline={true}
                                />
                            )}
                            <CheckboxComponent
                                customBoxClass={'minAgeNoTooltip'}
                                fieldName={'minAgeChecked'}
                                label={
                                    <Typography variant="body1" className="tags-title">
                                        Minimum Age
                                    </Typography>
                                }
                                customAction={handleMinAgeCheckbox}
                            />
                            {formik.values.minAgeChecked && (
                                <InputComponent
                                    formik={formik}
                                    inputValue={getIn(formik.values, 'minAge')}
                                    formikLabel={'minAge'}
                                    title={'Limit'}
                                    titleVariant={'body1'}
                                    inputClassName="captchaTxt"
                                    multiline={true}
                                />
                            )}
                            <MultipleSelectOptions
                                formik={formik}
                                listOfOptions={[...formik.values?.tags, ...tags]}
                                formikFieldName="tags"
                                subTitle="Tags"
                                inputLabel="Select Tag(s)"
                                enableAdditional={true}
                                additionalLabel="+ Add New Tag"
                                handleOpen={handleOpen}
                                boxStyleCustomClass={'boxCustomSize'}
                                modalComponent={
                                    <AddToListModal
                                        formikState={formik}
                                        formikFieldName="tags"
                                        value="tag"
                                        schema={tagsValidationSchema}
                                        header="+ Add New Tag"
                                        modalOpenState={open}
                                        listToInsertTo={tags}
                                        handleClose={setOpen}
                                    />
                                }
                            />
                        </>
                    )}
                    {(mechanic === 'Instant Win' ||
                        mechanic === 'Prize Draw (Lottery)') && (
                            <>
                                {mechanic !== 'Prize Draw (Lottery)' && (
                                    <>
                                        {' '}
                                        <CheckboxComponent
                                            fieldName={'queryVouchers'}
                                            label={
                                                <TooltipText
                                                    styleName="details-checkbox-tooltip"
                                                    text="Voucher History"
                                                    textVariant="body1"
                                                    tooltip="Allows you to show the history of claimed vouchers per participant"
                                                />
                                            }
                                            customAction={handleConfigFlow}
                                        />
                                        <CheckboxComponent
                                            fieldName={'listPrizes'}
                                            label={
                                                <TooltipText
                                                    styleName="details-checkbox-tooltip"
                                                    text="Prize Catalog"
                                                    textVariant="body1"
                                                    tooltip="Allows you to show the prize calatog"
                                                />
                                            }
                                            customAction={handleConfigFlow}
                                        />
                                        {formik.values.listPrizes && (
                                            <Box>
                                                <Typography variant="body1">
                                                    Prize List Priority
                                                </Typography>
                                                <RadioButton
                                                    formik={formik}
                                                    customAction={handleConfigFlow}
                                                    values={[
                                                        { value: 'ASC', label: 'Ascending' },
                                                        { value: 'DESC', label: 'Descending' },
                                                    ]}
                                                    radioGroupName={'priorityOrder'}
                                                />
                                            </Box>
                                        )}
                                        <CheckboxComponent
                                            fieldName={'teaser'}
                                            label={
                                                <TooltipText
                                                    styleName="details-checkbox-tooltip"
                                                    text="Promotion Тeaser"
                                                    textVariant="body1"
                                                    tooltip="Allows you to enable a promotion teaser before the start date to build consumers' awareness"
                                                />
                                            }
                                            customAction={handleTeaserCheckbox}
                                        />
                                        {formik.values.teaser && (
                                            <Box sx={{ width: '30%' }}>
                                                <Typography>Visible From</Typography>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterMoment}
                                                    locale="en-US"
                                                >
                                                    <DesktopDateTimePicker
                                                        value={moment(formik.values.visibleFromDate)}
                                                        ampm={false}
                                                        maxDate={
                                                            configData.configurationParameters
                                                                ?.configurationStartUtc
                                                                ? moment(
                                                                    configData.configurationParameters
                                                                        ?.configurationStartUtc
                                                                )
                                                                : undefined
                                                        }
                                                        onChange={(date) =>
                                                            formik.setFieldValue(
                                                                'visibleFromDate',
                                                                date?.format('YYYY-MM-DD HH:mm')
                                                            )
                                                        }
                                                        renderInput={(params) => (
                                                            <TextFieldWrapper
                                                                className="input-text"
                                                                {...params}
                                                            />
                                                        )}
                                                    ></DesktopDateTimePicker>
                                                </LocalizationProvider>
                                            </Box>
                                        )}
                                    </>
                                )}
                                <CheckboxComponent
                                    fieldName={'addCaptcha'}
                                    label={
                                        <Typography variant="body1" className="tags-title">
                                            Add Captcha Secret
                                        </Typography>
                                    }
                                    customAction={handleCaptchaCheckbox}
                                />

                                {mechanic === 'Prize Draw (Lottery)' && (
                                    //! Temp fix just to fix the bug in release-123 and will be refactored in BUG 710914
                                    <CheckboxComponent
                                        fieldName={'imageEntry'}
                                        label={
                                            <TooltipText
                                                styleName='image-promotion-checkbox-tooltip'
                                                text='Image Promotion Entry'
                                                textVariant='body1'
                                                tooltip={'The Image Promo Entry option will enable an image upload as a promotion entry'}
                                            />
                                        }
                                        customAction={handleImagePromotionEntry}
                                    />
                                )}

                                {formik.values.addCaptcha && (
                                    <InputComponent
                                        formik={formik}
                                        inputValue={getIn(formik.values, 'captchaSecret')}
                                        formikLabel={'captchaSecret'}
                                        title={'Captcha Secret'}
                                        titleVariant={'body1'}
                                        inputClassName="captchaTxt"
                                        multiline={true}
                                    />
                                )}
                                <InputComponent
                                    formik={formik}
                                    inputValue={getIn(formik.values, 'wv_url')}
                                    formikLabel={'wv_url'}
                                    title={'WebView URL'}
                                    titleVariant={'body1'}
                                    inputClassName="webview-input"
                                />
                                <MultipleSelectOptions
                                    formik={formik}
                                    listOfOptions={[...formik.values.tags, ...tags]}
                                    formikFieldName="tags"
                                    subTitle="Tags"
                                    inputLabel="Select Tag(s)"
                                    enableAdditional={true}
                                    additionalLabel="+ Add New Tag"
                                    handleOpen={handleOpen}
                                    boxStyleCustomClass={'boxCustomSize'}
                                    modalComponent={
                                        <AddToListModal
                                            formikState={formik}
                                            formikFieldName="tags"
                                            value="tag"
                                            schema={tagsValidationSchema}
                                            header="+ Add New Tag"
                                            modalOpenState={open}
                                            listToInsertTo={tags}
                                            handleClose={setOpen}
                                        />
                                    }
                                />
                            </>
                        )}
                </Box>
                <Box className="rightChildDiv">
                    {promoFlow === 'instantWin' && (
                        <Box className="interruptedFlowBox">
                            <TooltipText
                                styleName="details-checkbox-tooltip"
                                text="Interrupted Flow"
                                textVariant="body1"
                                tooltip="Once enabled, Reserved status will be used for vouchers that are distributed within this promotion"
                            />
                            <RadioButton
                                formik={formik}
                                values={[
                                    { value: 'true', label: 'Yes' },
                                    { value: 'false', label: 'No' },
                                ]}
                                radioGroupName={'useStatusReserved'}
                                customAction={handleInterruptedFlow}
                            />
                            {formik.values.useStatusReserved === 'true' && (
                                <>
                                    <TooltipText
                                        text="Maximum vouchers to accept"
                                        textVariant="body1"
                                        styleName="details-checkbox-tooltip"
                                        iconSize="medium"
                                        tooltip="Multiple vouchers can be accepted per request."
                                    />
                                    <InputNumberSpinner
                                        fieldName={'maxParticipationIds'}
                                        incrementValue={1}
                                        minValue={1}
                                        maxValue={100}
                                    />
                                </>
                            )}
                        </Box>
                    )}
                    <Box>
                        <Typography variant="body1">Identity Provider</Typography>
                        <RadioButton
                            formik={formik}
                            values={[
                                { value: 'cds', label: 'CDS' },
                                { value: 'cid', label: 'CID' },
                            ]}
                            radioGroupName={'userIdType'}
                        />
                    </Box>
                    <Box className="saveButtonGroup">
                        {formik.dirty && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => formik.resetForm()}
                            >
                                Discard
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            className="rightButton"
                            color="primary"
                            type="submit"
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export { AdditionalDetailsForm };

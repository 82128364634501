import { Button, Input, Box, Grid, LinearProgress, Typography } from '@mui/material';
import { useRef, useState, SyntheticEvent, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import DeleteIcon from '@mui/icons-material/Delete';
import './FileUpload.css';
import { appendPublicDomainName } from './fileUploadHelpers';
import { SetNotificationStateType } from '../../../types/notifications';
import TextFieldWrapper from '../../../common/TextFieldWrapper/TextFieldWrapper';

interface FileUploadProps {
    uploadCallback?: any;
    deleteCallback?: any;
    placeholder?: string;
    filePath: string;
    maxFiles?: number;
    usePublicDomain?: boolean;
    fileAccess?: string;
    bucket?: string;
    acl?: string;
    cachedFiles?: Array<any>;
    backstepDone?: boolean;
    isEdit?: boolean;
    headerValidation?: boolean;
    customAllowedFileTypes?: string[];
    setNotificationState: SetNotificationStateType;
    headerValidatorFunc?: (file: File) => Promise<void>
}

const FileUpload = ({
    isEdit,
    backstepDone,
    placeholder,
    filePath,
    maxFiles = 1,
    uploadCallback,
    deleteCallback,
    usePublicDomain = false,
    fileAccess = 'public',
    bucket = 'private',
    acl = 'public-read',
    cachedFiles = [],
    headerValidation = false,
    customAllowedFileTypes,
    setNotificationState,
    headerValidatorFunc
}: FileUploadProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>();
    const [files, setFile] = useState<File[]>([]);
    const [progressInfo, setProgress] = useState<Array<{ fileName: string; percent: number }>>([]);
    let progressFiles: Array<{ fileName: string; percent: number }> = [];

    useEffect(() => {
        Storage.configure({
            customPrefix: {
                public: '',
                protected: '',
                private: '',
            },
            level: fileAccess,
        });
    }, [fileAccess]);

    const validateFileType = (fileType: string) => {
        if (customAllowedFileTypes) {
            return customAllowedFileTypes.includes(fileType);
        }

        const fileExtension = fileType.slice(0, fileType.indexOf('/'));
        switch (fileExtension) {
            case 'image':
                return ['image/jpeg', 'image/png', 'image/svg+xml'].includes(fileType);
            case 'text':
                return [
                    '',
                    'text/plain',
                    'text/x-csv',
                    'text/csv',
                    'application/vnd.ms-excel',
                    'application/csv',
                    'application/x-csv',
                    'text/comma-separated-values',
                    'text/x-comma-separated-values',
                    'text/tab-separated-values',
                ].includes(fileType);
            default:
                return false;
        }
    };

    const prepareValidFiles = async (files: File[]) => {
        const validFiles: File[] = [];
        await Promise.all(
            files.map(async (file: File) => {
                const isValidFile = validateFileType(file.type);
                let validHeader = true;

                if (headerValidation) {
                    try {
                        await headerValidatorFunc(file);
                    } catch (e) {
                        validHeader = false;
                    }
                }

                if (!isValidFile || !validHeader) {
                    setNotificationState({
                        open: true,
                        title: 'Error',
                        content: !isValidFile
                            ? `Invalid file type: ${file.name}`
                            : `File validation failed: ${file.name}`,
                        level: 'error',
                    });
                } else {
                    validFiles.push(file);
                    progressFiles.push({ fileName: file.name, percent: 0 });
                }
            })
        );

        return validFiles;
    };

    const uploadToStorage = async (files: File[]) => {
        const result = await Promise.all(
            files.map((file: File, index: number) => {
                const opt: { [key: string]: string | ((x: any) => void) } = {
                    contentType: file.type,
                    progressCallback(progressEvent: { [key: string]: any }) {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        const progressFileInfo = { fileName: file.name, percent: progress };
                        progressFiles[index] = progressFileInfo;
                        setProgress([...progressInfo, ...progressFiles]);
                    },
                };

                if (bucket !== 'private') {
                    opt.bucket = awsconfig.aws_user_files_s3_bucket.replace('private', bucket);
                    opt.acl = acl;
                }

                return Storage.put(`${filePath}/${file.name}`, file, opt);
            })
        )
            .then((res: any) => {
                return res.map(({ key }: any) => (usePublicDomain ? appendPublicDomainName({
                    path: key,
                    bucketName: awsconfig.aws_user_files_s3_bucket
                }) : key));
            })
            .catch((err: ErrorEvent) => {
                console.error('Error uploading file', err);
                setProgress([]);
                setFile([]);
            });

        return result;
    };

    const getTextInputValue = () => {
        const items = files.length ? files : cachedFiles;
        return items
            .map((item: any) => {
                return item.name?.replace(`${usePublicDomain ? appendPublicDomainName({
                    path: filePath,
                    bucketName: awsconfig.aws_user_files_s3_bucket
                }) : filePath}/`, '');
            })
            .join(',');
    };

    const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFiles = Array.from(e.target.files);
        const uniqueUploadedFiles = uploadedFiles.filter((file: File) => {
            const duplicate = files.some((item: File) => item.name === file.name);
            if (!duplicate) return true;

            setNotificationState({
                open: true,
                title: 'Error',
                content: `File ${file.name} already uploaded`,
                level: 'error',
            });
            return false;
        });

        if (maxFiles && uniqueUploadedFiles.length > maxFiles) {
            e.preventDefault();
            setNotificationState({
                open: true,
                title: 'Error',
                content: `Cannot upload more than ${maxFiles} file(s)`,
                level: 'error',
            });
            return;
        }
        const validFiles = await prepareValidFiles(uniqueUploadedFiles);
        const fileKeys = await uploadToStorage(validFiles);

        if (uploadCallback && validFiles.length > 0) {
            uploadCallback(fileKeys.map((key: string) => key.split('/').at(-1)));
        }

        setFile(Array.from(new Set([...files, ...validFiles])));
    };

    const handleDeleteFile = async (fileName: string) => {
        try {
            const newFiles = files.filter((file: File) => file.name !== fileName);
            const newProgressInfo = progressInfo.filter((obj: { fileName: string }) => obj.fileName !== fileName);
            const opt: any = {};

            if (bucket !== 'private') {
                opt.bucket = awsconfig.aws_user_files_s3_bucket.replace('private', bucket);
            }

            await Storage.remove(`${filePath}/${decodeURI(fileName)}`, opt);

            if (deleteCallback) {
                deleteCallback(
                    usePublicDomain ? appendPublicDomainName({
                        path: `${filePath}/${decodeURI(fileName)}`,
                        bucketName: awsconfig.aws_user_files_s3_bucket
                    }) : fileName
                );
            }

            setProgress(newProgressInfo);
            setFile(newFiles);
        } catch (err) {
            console.error('Deleting file failed with:', err);
        }
    };

    const handleClick = (_: SyntheticEvent) => {
        hiddenFileInput.current?.click();
    };

    return (
        <Box>
            <Grid container direction='row'>
                <Grid item xs={9}>
                    <TextFieldWrapper
                        sx={{ width: '400px' }}
                        className='file-input-field'
                        placeholder={placeholder}
                        disabled
                        value={getTextInputValue()}
                    />
                </Grid>
                <Grid item xs={3}>
                    {(files.length || cachedFiles.length) < maxFiles && (
                        <Button className='file-button' variant='contained' color='primary' onClick={handleClick}>
                            Choose File
                        </Button>
                    )}
                </Grid>

                <Grid item>
                    <Input
                        inputRef={hiddenFileInput}
                        type='file'
                        inputProps={{ multiple: true, 'data-testid': 'fileInput' }}
                        onChange={handleUpload}
                        style={{ display: 'none' }}
                    />
                </Grid>
            </Grid>

            {progressInfo && progressInfo !== null && (
                <Grid container={true} className='grid-container'>
                    {progressInfo.map((obj: { fileName: string; percent: number }, i: number) => (
                        <Grid key={i} container className='grid-box'>
                            <Grid item xs={10}>
                                <Typography variant='body2' className='file-name'>{`${obj.fileName}`}</Typography>
                                <LinearProgress variant='determinate' value={obj.percent} />
                                <Typography variant='body2' color='black'>{`${Math.round(obj.percent)}%`}</Typography>
                            </Grid>
                            {obj.percent === 100 && (
                                <Grid item xs={2} className='delete-file-grid'>
                                    <DeleteIcon
                                        className='delete-icon'
                                        onClick={() => {
                                            handleDeleteFile(obj.fileName);
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            )}
            <Grid container={true} className='grid-container'>
                {(backstepDone || isEdit) &&
                    cachedFiles &&
                    cachedFiles.map((file: any, i: number) => {
                        const fileName =
                            file &&
                            file?.name?.replace(
                                `${usePublicDomain ? appendPublicDomainName({
                                    path: filePath,
                                    bucketName: awsconfig.aws_user_files_s3_bucket
                                }) : filePath}/`,
                                ''
                            );
                        return file &&
                            !progressInfo.some(
                                (obj: { [key: string]: string | number }) => obj.fileName === decodeURI(file)
                            ) ? (
                            <Grid key={i} container className='grid-box'>
                                <Grid item xs={10}>
                                    <Typography
                                        variant='body2'
                                        className='existing-file-name'>{`${fileName}`}</Typography>
                                </Grid>
                                <Grid item xs={2} className='delete-file-grid'>
                                    <DeleteIcon
                                        className='delete-icon'
                                        onClick={() => {
                                            handleDeleteFile(fileName);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ) : null;
                    })}
            </Grid>
        </Box>
    );
};

export { FileUpload };

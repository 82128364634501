import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Container } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

interface props {
    earliestDate: string,
    latestDate: string
    handleChange: any,
    customParams: any,
    valueToDisplay?: 'start' | 'end'
    
}
const DateTimePicker = ({earliestDate, handleChange, customParams, latestDate, valueToDisplay } : props) => {

    const [minDate, setMinDate] = useState(moment())
    const [maxDate, setMaxDate] = useState(moment())
    const [dateValue, setDateValue] = useState(moment())

    useEffect(() => {
        if (earliestDate !== '' && latestDate !== '') {
                const initialMinDate = moment(earliestDate);
                setMinDate(initialMinDate)
                const initialMaxDate = moment(latestDate)
                setMaxDate(initialMaxDate)
                valueToDisplay === 'start' ? setDateValue(initialMinDate) : setDateValue(initialMaxDate)
            }

    }, [earliestDate, latestDate, valueToDisplay]);

    return (
        <Container className='datetimeContainer' disableGutters>
            <LocalizationProvider dateAdapter={AdapterMoment} locale='en-US'>
            <DesktopDateTimePicker
                minDate={minDate}
                maxDate={maxDate}
                ampm={false}
                value={dateValue}
                onChange={dateValue =>
                    handleChange({ date: dateValue?.format('YYYY-MM-DD HH:mm'), ...customParams})}
                renderInput={(params) => <TextFieldWrapper size="small" className='input-text'
                {...params}/>}
                InputProps={{sx: {fontSize: '13px'}}}
                components= {{ OpenPickerIcon: ArrowDropDownIcon }}
            />
            </LocalizationProvider>
        </Container>
    )

}
export { DateTimePicker }
